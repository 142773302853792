import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../providers/AuthProvider.js';
import AdminLayout from "../Layout/AdminLayout";

const ClientRoute = ({ component: Component, sidebar, searchbar, ...rest }) => {

    const [authState, authDispatch] = useAuthContext();
    const { auth } = authState;
    const { authIsLogged, authLogout } = authDispatch

    if (authIsLogged() && auth.roles.includes('ROLE_ADMIN')) {
        return <AdminLayout {...rest}><Component {...rest} /></AdminLayout>;
    } else {
        authLogout();
        return <Navigate to="/login-admin" />
    }
};

export default ClientRoute;
