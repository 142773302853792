import React, { useRef,  useState, useEffect } from 'react';
import { Table, Card, Button } from 'antd';

import { useApiContext } from '../providers/ApiProvider.js';

const ApiCollectionLatest = ({title, contentVersion, onClick, fetch_endpoint, delete_endpoint, pageSize, columns, showHeader = false, filters = [], ...rest}) => {

    onClick = onClick || function(){};

    const [apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;

    const [editId, setEditId ] = useState(null)
    const [data, setData ] = useState([])
    const [pagination, setPagination ] = useState({
        current:1,
        pageSize:parseInt(pageSize) || 3,
        position:'bottom',
    })
    const [loading, setLoading ] = useState(false)
    const [error, setError ] = useState(false)

    // internal trigger to force natural data fetch based on state change
    const [needUpdate, setNeedUpdate ] = useState(0)
    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        // fetch at startup and whenever needUpdate is changed
        let params = {
            itemsPerPage: pagination.pageSize,
            page: pagination.current,
            sortField: "createdAt",
            sortOrder: "DESC",
            search: rest.customSearch ? rest.customSearch : {},
            filters: filters
        };
        fetch(params);

        return () => {
            isMounted.current = false;
        }
    },[needUpdate,contentVersion, rest.updateElement])

    const handleTableChange = (table_pagination, table_filters, table_sorter) => {
        // update current pagination
        const pager = { ...table_pagination };
        pager.current = table_pagination.current;
        setPagination(pager)
        // trigger a data refresh
        setNeedUpdate((new Date()).getTime());
    };

    const fetch = async (params) => {
        setLoading(true)
        let hydra_response = await apiFetchCollection(fetch_endpoint, params);
        let pager =  { ...pagination };
        if (!isMounted.current) {return }

        if(hydra_response)
        {
            setError(false);
            pager.total = hydra_response['hydra:totalItems'];
            setPagination(pager)
            setData(hydra_response['hydra:member'])
            // if no result and not in first result page => go to the last available one
            if(hydra_response['hydra:member'].length === 0 && pager.current > 0)
            {
                pager.current  = Math.floor(pager.total / pager.pageSize);
                setPagination(pager);
                setNeedUpdate((new Date()).getTime());
            }
        }
        else
        {
            setError(true);
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            {
                error
                    ? <Card type="inner" title={typeof title === 'function' ? title() : title}><Button type="danger" onClick={fetch}>Error while loading. Retry ?</Button></Card>
                    : <Table
                        title={title || false}
                        bordered={true}
                        className="ApiCollectionLatest"
                        columns={columns}
                        rowKey={record => record['@id']}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        showHeader={showHeader}
                        scroll={{ x: 'auto' }}
                        size="small"
                        style={{marginBottom:"1em"}}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onClick(record); }, // click row
                            };
                        }}
                        {...rest}
                    />
            }
        </React.Fragment>
    );
};

export default ApiCollectionLatest;
