import React, {useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import {Button, Card, Form, Input, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useCheckoutContext} from "../../providers/CheckoutProvider";

const Brand = ({setNeedUpdate}) => {

    const {t} = useTranslation();

    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const {brand, account} = checkoutState;
    const {setBrand} = checkoutDispatch;

    const [loading, setLoading] = useState(false);

    async function handleFinish(values) {
        setLoading(true)
        values.account = `/api/accounts/${account.id}`
        const response = await apiPostEntity('brands', values)
        setBrand(response)
        setNeedUpdate(needUpdate => needUpdate + 1)
        setLoading(false)
    }

    function handleChange(value) {
        if (!value)
            setBrand(null);
        else
            setBrand(account.brands.find(brand => brand.id === value));
    }

    return (
        <div>
            <h2 className="mb-1em text-center">Marque / Hôtel</h2>

            {!!account?.brands?.length &&
                <Space className="mb-1em">
                    <Select allowClear style={{width: 250}} placeholder="Selectionner une société"
                            onChange={handleChange} value={brand?.name}>
                        <Select.Option key="empty" value="empty">Aucune</Select.Option>
                        {account?.brands.map(brand => (
                            <Select.Option key={brand.id} value={brand.id}>{brand.name}</Select.Option>
                        ))}
                    </Select>
                </Space>}

            {brand &&
                <div>
                    <Card title={brand.name}>
                        <p>{brand.address}</p>
                        <p>{brand.city} {brand.zipCode}</p>
                        <p>{brand.country}</p>
                    </Card>
                </div>}

            {!brand &&
                <Form
                    layout="vertical"
                    onFinish={handleFinish}
                >

                    <Form.Item
                        label={t('Nom')}
                        name="name"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Adresse')}
                        name="address"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Ville')}
                        name="city"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Code Postal')}
                        name="zipCode"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Pays')}
                        name="country"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Valider
                        </Button>
                    </Form.Item>

                </Form>}
        </div>
    );
};

export default Brand;