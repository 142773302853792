import React, { useEffect, useState } from 'react';
import { message, Result, Spin, Steps } from "antd";
import Identification from "../components/Checkout/Identification";
import AccountInformation from "../components/Checkout/AccountInformation";
import { useCheckoutContext } from "../providers/CheckoutProvider";
import Payment from "../components/Checkout/Payment";
import { useAuthContext } from '../providers/AuthProvider';
import { useApiContext } from '../providers/ApiProvider';
import { useParams } from 'react-router-dom';
import StripeContainer from '../components/Stripe/StripeContainer';

const { Step } = Steps;

const CheckoutProcess = () => {

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const { setupCheckout } = checkoutDispatch;
    const [apiDispatch] = useApiContext();
    const { apiPostEntityPublic } = apiDispatch;
    const [authState, authDispatch] = useAuthContext();
    const { authIsLogged } = authDispatch;
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const params = useParams()
    const [error, setError] = useState(null)

    useEffect(() => {
        if (authIsLogged() && currentStep === 0) {
            setCurrentStep(1)
        }
    }, [])

    useEffect(() => {
        async function fetchConfig() {
            console.log('first')
            setLoading(true)
            await fetchPrice()
            setLoading(false)
        }
        fetchConfig()
    }, [])

    async function fetchPrice() {
        const response = await apiPostEntityPublic(`checkout/price/${params.priceId}`, {});
        if (response.success) {
            return setupCheckout(response);
        }
        console.log('second')

        setError(true)
        return message.error(response?.error || 'Il y a eu une erreur');
    }


    const carousel = [
        <Identification key={0} setCurrentStep={setCurrentStep} />,
        <AccountInformation key={1} setCurrentStep={setCurrentStep} />,
        <Payment key={2} />
    ]

    if (error) {
        return (<Result
            status="404"
            title="404"
            subTitle="Désolé, le produit demandé n'est pas disponible."
        // extra={<Button type="primary">Back Home</Button>}
        />
        )
    }

    return (
        <Spin spinning={loading}>
            <StripeContainer>
                <div style={{ minHeight: '100vh' }}>
                    <div style={{ padding: 100 }}>
                        <Steps current={currentStep}>
                            <Step title="Identification" />
                            <Step title="Informations supplémentaires" />
                            <Step title="Paiement" />
                        </Steps>
                    </div>

                    <div style={{
                        width: 800,
                        padding: 50,
                        margin: "auto",
                        backgroundColor: '#fff'
                    }}>
                        {carousel[currentStep]}
                    </div>
                </div>
            </StripeContainer>
        </Spin>
    );
};

export default CheckoutProcess;