import React from 'react';
import Layout from '../Layout/PublicLayout.js';

const PublicRoute = ({ component: Component, layout, ...rest }) => {

  return (
    <Layout visitor={rest.visitor} layout={layout} private={false} {...rest}><Component {...rest} /></Layout>
  );
};

export default PublicRoute;
