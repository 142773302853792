import React, {useEffect, useState} from 'react';
import {useAuthContext} from "../../providers/AuthProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";
import {useCheckoutContext} from "../../providers/CheckoutProvider";
import CompanyChoice from "./CompanyChoice";
import BrandChoice from "./BrandChoice";
import {Button} from "antd";

const AccountInformation = ({setCurrentStep}) => {

    const {t} = useTranslation();

    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const {authIsLogged} = authDispatch;

    const [apiDispatch] = useApiContext();
    const {apiFetchEntity} = apiDispatch;

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const {brand, company} = checkoutState;
    const {setAccount} = checkoutDispatch;

    const [needUpdate, setNeedUpdate] = useState(0);

    useEffect(() => {
        fetch()
    }, [needUpdate])

    async function fetch() {
        const response = await apiFetchEntity('accounts', auth.accountId);
        setAccount(response)
    }

    if (!authIsLogged())
        return setCurrentStep(0);

    return (
        <div>

            <CompanyChoice setNeedUpdate={setNeedUpdate}/>

            <BrandChoice setNeedUpdate={setNeedUpdate}/>


            {brand && company &&
            <div className="mt-2em">
                <Button type="primary" onClick={() => setCurrentStep(2)}>Passer au paiement</Button>
            </div>}
        </div>
    );
};

export default AccountInformation;