import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Radio, Select, Space, Spin } from 'antd';
import { useApiContext } from '../providers/ApiProvider';
import { useAuthContext } from '../providers/AuthProvider';
import { useTranslation } from 'react-i18next';

export default function CompanyForm({handleCompanyChange}) {

    const { t } = useTranslation();
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResource, apiPostEntity } = apiDispatch;
    const [authState] = useAuthContext();
    const { auth } = authState;
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();
    const newCompany = Form.useWatch('newCompany', form);
    
    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        console.log("account", auth);

        setLoading(true);
        const response = await apiFetchSubResource(
            "accounts",
            { id: auth.accountId },
            "companies"
        );

        if (response["hydra:member"]) {
            let data = response["hydra:member"];
            setCompanies(data);
        }

        setLoading(false);
        console.log("response", response);
    };

    async function handleSubmit(values) {
        console.log('values', values)
        setLoading(true)
        let selectedCompany = null;
        
        if (values.companyId) {
            selectedCompany = companies.find(item => item.id === values.companyId)
        } else {
            selectedCompany = await createCompany(values)
        }
        
        handleCompanyChange(selectedCompany)
        setLoading(false)
    }

    const createCompany = async values => {
        values.account = `/api/accounts/${auth.accountId}`
        const response = await apiPostEntity('companies', values)
        return response;
    }

    return (
        <Spin spinning={loading}>
            <h3>Société à facturer</h3>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ newCompany: false }}
            >
                <Form.Item name="newCompany" >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={false}>Choisir une société existante</Radio>
                            <Radio value={true}>Créer une nouvelle société</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {
                    companies.length && !newCompany &&
                    <>
                        <Form.Item
                            label={t('Société')}
                            name="companyId"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Select
                                style={{ width: 250 }}
                                placeholder="Selectionner une société"
                            >
                                {
                                    companies.map((company) => (
                                        <Select.Option key={company.id} value={company.id}>
                                            {company.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </>
                }

                {
                    (newCompany || !companies.length) &&
                    <>
                        <Form.Item
                            label={t('Nom')}
                            name="name"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t('Adresse')}
                            name="address"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t('Ville')}
                            name="city"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t('Code Postal')}
                            name="zipCode"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t('Pays')}
                            name="country"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>

                    </>
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Suivant
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}
