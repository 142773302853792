import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../providers/AuthProvider.js';
import PrivateLayout from '../Layout/PrivateLayout.js';

const PrivateRoute = ({ component: Component, sidebar, searchbar, ...rest }) => {

    const [authState, authDispatch] = useAuthContext();
    const { auth } = authState;
    const { authIsLogged, authLogout } = authDispatch

    if (authIsLogged()) {
        return <PrivateLayout {...rest}><Component {...rest} /></PrivateLayout>;
    } else {   
        authLogout();
        return <Navigate to="/login-admin" />
    }
};

export default PrivateRoute;
