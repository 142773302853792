import React, {useEffect, useState} from 'react'
import {useAuthContext} from "../../providers/AuthProvider";
import ModalContact from "../../components/contact/ModalContact";
import {Button, Modal, Space} from "antd";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import CollectionTable from "../../components/CollectionTable";
import {useApiContext} from "../../providers/ApiProvider";

const {confirm} = Modal;

export default function Contacts() {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch;

    const [visible, setVisible] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [selectedContact, setSelectedContact] = useState(null)

    const columns = [
        {
            title: 'Prénom',
            dataIndex: 'firstname'
        },
        {
            title: 'Nom',
            dataIndex: "lastname"
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Role',
            dataIndex: 'role'
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (id, record) => (
                <Space size="large">
                    <EditTwoTone onClick={() => setSelectedContact(record)}/>
                    <DeleteTwoTone twoToneColor="red" onClick={() => showDeleteConfirm(id)}/>
                </Space>
            )
        }
    ]

    useEffect(() => {
        if (selectedContact)
            setVisible(true)
    }, [selectedContact])

    useEffect(() => {
        if (!visible && selectedContact)
            setSelectedContact(null)
    }, [visible])

    function showDeleteConfirm(id) {
        confirm({
            title: 'Etes-vous sûr de vouloir supprmer ce contact ?',
            icon: <ExclamationCircleOutlined/>,
            content: 'Ce contact sera perdu définitivement',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {

                return new Promise(async (resolve, reject) => {
                    const response = await apiDeleteEntity('contacts', id);
                    setNeedUpdate(needUpdate + 1)
                    resolve()
                }).catch(() => console.log('Oops errors!'));

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <>
            <h1>Gérer vos Contacts</h1>

            <Space direction="vertical" size="large" style={{width: '100%'}}>
                <div>
                    <Button
                        type="primary" onClick={() => setVisible(true)}
                        icon={<PlusOutlined/>}
                    >
                        Ajouter un contact
                    </Button>
                </div>

                <div>

                    <CollectionTable
                        endpoint={`accounts/${auth.accountId}/contacts`}
                        reload={needUpdate}
                        columns={columns}
                    />

                </div>
            </Space>

            {visible &&
                <ModalContact
                    visible={visible} setVisible={setVisible} reload={() => setNeedUpdate(new Date().getTime())}
                    contact={selectedContact}
                />}
        </>
    )
}
