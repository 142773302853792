import React, { useState } from 'react'
import { Button, Form, Input, InputNumber, message, Modal, Radio, Select, Spin } from "antd";
import { useApiContext } from '../../providers/ApiProvider';

export default function CouponForm({ handleClose, coupon, modal = true }) {

  const [apiDispatch] = useApiContext();
  const { apiPostEntity } = apiDispatch;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const type = Form.useWatch("type", form);
  const duration = Form.useWatch("duration", form);

  const handleSubmit = (values) => {
    setLoading(true)
    if (coupon.id === "new") {
      createCoupon(values)
    }

    setLoading(false)
  }

  const createCoupon = async (values) => {

    if (values.amountOff) values.amountOff = values.amountOff*100
    values.currency = 'eur';
    const response = await apiPostEntity("coupons", values);
    if (response["@type"] === "hydra:Error") {
      return message.error(response["hydra:description"]);
    }
    return message.success('Coupon crée avec succès.')
  };

  const content = (
    <Spin spinning={loading}>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
        initialValues={{
          percentOff: coupon?.percentOff || 10,
          amountOff: coupon?.amountOff || 10,
          type: coupon?.type || 'percent',
          duration: coupon?.duration || 'once',
        }}
      >
        <Form.Item
          label="Nom du coupon"
          name="name"
          rules={[{ required: true, message: "Le nom est requis" }]}
        >
          <Input placeholder="Nom du coupon" />
        </Form.Item>
        {coupon.id === "new" && (
          <>
            <Form.Item name="type" label="Type">
              <Radio.Group>
                <Radio value="percent">Réduction en pourcentage</Radio>
                <Radio value="amount">Réduction d'un montant fixe</Radio>
              </Radio.Group>
            </Form.Item>
            {type !== "setup" && (
              <>
                {type === 'percent' ? (
                  <Form.Item
                    label="Pourcentage de réduction"
                    name="percentOff"
                  >
                    <InputNumber min={1}/>
                  </Form.Item>
                )
                  :
                  <>
                    <Form.Item
                      label="Montant de la réduction"
                      name="amountOff"
                    >
                      <InputNumber min={1}/>
                    </Form.Item>
                  </>
                }
                <Form.Item
                  rules={[{ required: true, message: "Ce champ est requis" }]}
                  label="Validité" name="duration" >
                  <Select
                    options={[
                      { label: "Plusieurs mois", value: "repeating" },
                      { label: "Permanent", value: "forever" },
                      { label: "Une fois", value: "once" },
                    ]}
                  />
                </Form.Item>
                {
                  duration === "repeating" && (
                    <Form.Item
                      label="Nombre de mois"
                      name="durationInMonths"
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                  )
                }
              </>
            )}
          </>
        )}
        {!modal && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </Spin>
  );

  return (
    <Modal
      title="Ajouter coupon"
      visible
      onOk={() => form.submit()}
      onCancel={handleClose}
      okText="Créer"
      cancelText="Annuler"
    >
      {content}
    </Modal>
  )
}
