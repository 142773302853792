import React, {useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import {Button, Card, Form, Input, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useCheckoutContext} from "../../providers/CheckoutProvider";

const CompanyChoice = ({setNeedUpdate}) => {

    const {t} = useTranslation();

    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const {company, account} = checkoutState;
    const {setCompany} = checkoutDispatch;

    const [loading, setLoading] = useState(false);

    async function handleFinish(values) {
        setLoading(true)
        values.account = `/api/accounts/${account.id}`
        const response = await apiPostEntity('companies', values)
        setCompany(response)
        setNeedUpdate(needUpdate => needUpdate + 1)
        setLoading(false)
    }

    function handleChange(value) {
        if (!value)
            setCompany(null);
        else
            setCompany(account.companies.find(company => company.id === value));
    }

    return (
        <div>
            <h2 className="mb-1em text-center">Société a facturer</h2>

            {!!account?.companies?.length &&
                <Space className="mb-1em">
                    <Select allowClear style={{width: 250}} placeholder="Selectionner une société"
                            onChange={handleChange} value={company?.name}>
                        <Select.Option key="empty" value="empty">Aucune</Select.Option>
                        {account?.companies.map(company => (
                            <Select.Option key={company.id} value={company.id}>{company.name}</Select.Option>
                        ))}
                    </Select>
                </Space>}

            {company &&
                <div>
                    <Card title={company.name}>
                        <p>{company.address}</p>
                        <p>{company.city} {company.zipCode}</p>
                        <p>{company.country}</p>
                    </Card>
                </div>}

            {!company &&
                <Form
                    layout="vertical"
                    onFinish={handleFinish}
                >

                    <Form.Item
                        label={t('Nom')}
                        name="name"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Adresse')}
                        name="address"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Ville')}
                        name="city"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Code Postal')}
                        name="zipCode"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Pays')}
                        name="country"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Valider
                        </Button>
                    </Form.Item>

                </Form>}
        </div>
    );
};

export default CompanyChoice;