import React, {useState} from "react";
import {Button, Form, Input, Modal, message} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalForgetPassword() {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiPostEntityPublic} = apiDispatch

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const [form] = Form.useForm();

    async function handleOk() {
        setLoading(true);

        const response = await apiPostEntityPublic('public/forget-password', form.getFieldsValue());

        if (!response.success)
            message.error(t(response.message))
        else {
            message.success(t('Un email vient de partir a cette adresse avec un nouveau mot de passe'));
            setTimeout(() => setVisible(false), 1000);
        }

        setLoading(false);
    }

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>
                {t('Mot de passe oublié')}
            </Button>

            <Modal
                title={t('Mot de passe oublié')}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        {t('Annuler')}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        {t('Demander nouveau mot de passe')}
                    </Button>
                ]}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label={t('Veuillez entrer votre adresse mail')}
                        name="email"
                        rules={[
                            {required: true, message: t('email requis')},
                            {type: 'email', message: t('email non valide!')}
                        ]}>
                        <Input placeholder="mon-adresse@email.com" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}