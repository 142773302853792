import React from 'react'
import { Layout, Menu, } from 'antd';
import { Link, useLocation } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import { ContainerOutlined, FileTextOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import BrandLogo from './BrandLogo';

const { Sider, Content } = Layout;

export default function AdminLayout({ children }) {

    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Layout className="mainLayout">
            <Sider width={300} style={{
                overflow: 'auto', height: '100vh', backgroundColor: 'white',
                position: 'fixed', left: 0, top: 0, bottom: 0,
            }}>
                <BrandLogo />
                <Menu selectedKeys={[location.pathname]} items={[
                    { label: <Link to="/admin/dashboard">{t('dashboard')}</Link>, title: 'Dashboard', key: '/admin/dashboard', icon: <HomeOutlined /> },
                    { label: <Link to="/admin/contracts">{t('abonnements')}</Link>, title: 'Abonnements', key: '/admin/contracts', icon: <ContainerOutlined /> },
                    { label: <Link to="/admin/invoice">{t('factures')}</Link>, title: 'Factures', key: '/admin/invoices', icon: <FileTextOutlined /> },
                    {
                        label: <div><SettingOutlined /> {t('Stripe settings')}</div>, title: 'Settings', key: '/settings', type: 'group', children: [
                            { label: <Link to="/admin/products">{t('produits')}</Link>, title: 'Produits', key: '/admin/stripe-products' },
                            { label: <Link to="/admin/coupons">{t('coupons')}</Link>, title: 'Coupons', key: '/admin/stripe-coupons' },
                            { label: <Link to="/admin/stripe/logs">{t('logs')}</Link>, title: 'Logs', key: 'admin/stripe/logs' },
                        ]
                    },
                ]} />
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <AvatarDropdown />
                </div>
            </Sider>
            <Layout style={{ marginLeft: 300 }}>
                <Content>
                    <Layout className="site-layout-background">
                        <Content style={{ padding: "2em", minHeight: '100vh' }}>
                            {children}
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        </Layout>
    )
}