import React, { useState } from "react";
import { useApiContext } from "../../providers/ApiProvider";
import { Button, Form, Input, InputNumber, message, Modal, Radio, Select, Spin, Switch} from "antd";
import TiersFormTable from "./TiersFormTable";

export default function PriceForm({ modal, handleClose, product, price }) {
    
    const [apiDispatch] = useApiContext();
    const { apiPostEntity, apiUpdateEntity } = apiDispatch;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const recurring = Form.useWatch("recurring", form);
    const type = Form.useWatch("type", form);
    const billingScheme = Form.useWatch("billingScheme", form);
    const tiersData = Form.useWatch("tiers", form);

    const handleSubmit = async (values) => {

        setLoading(true);

        if (price.id === "new") {
            await createPrice(values);
        } else {
            await updatePrice(values);
        }
        handleClose(true);
        setLoading(false);
    };

    const createPrice = async (values) => {
        let data = {
            name: values.name,
            active: true,
            currency: "eur",
            amount: values.amount || 0,
            product: product["@id"],
            recurring: values.type !== "setup" ? values.recurring : false,
            type: values.type,
            billingScheme: values.billingScheme || 'per_unit'
        };
        if (data.billingScheme === 'tiered') {
            data.tiersMode = 'volume';
            data.tiers = values.tiers;
        }
        if (data.recurring) {
            data.recurringInterval = values.interval;
        }
        console.log('data', data)
        // return console.log('tiers', values.tiers)
        const response = await apiPostEntity("prices", data);
        console.log("response", response);
        if (response["@type"] === "hydra:Error") {
            return message.error(response["hydra:description"]);
        }
        return message.success('Prix crée avec succes.')
    };

    const updatePrice = async (values) => {
        let data = {
            name: values.name,
            active: values.active,
        };

        const response = await apiUpdateEntity("prices", price.id, data);
        if (response["@type"] === "hydra:Error") {
            return message.error(response["hydra:description"]);
        }
    };

    const handleTiers = (tiersValues) => {
        return tiersValues.map((value, index) => {
            return { up_to: value.up_to || 'inf', flat_amount: value.flat_amount * 100, unit_amount: value.unit_amount * 100 }
        })
    }

    const content = (
        <Spin spinning={loading}>
            <Form
                onFinish={handleSubmit}
                layout="vertical"
                form={form}
                initialValues={{
                    ...price,
                    amount: price.amount || 0,
                    billingScheme: price?.billingScheme || "per_unit",
                    active: price.active || true,
                    type: price?.type || 'price',
                    recurring: false,
                    interval: price?.recurringInterval || "month",
                    tiers: [
                        { up_to: 1, flat_amount: 0, unit_amount: 0 },
                        { flat_amount: 0, unit_amount: 0 }
                    ]
                }}
            >
                <Form.Item
                    label="Nom du prix"
                    name="name"
                    rules={[{ required: true, message: "Le nom est requis" }]}
                >
                    <Input placeholder="input price name" />
                </Form.Item>
                {price.id !== "new" &&
                    <Form.Item label="Actif" name="active" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                }
                {price.id === "new" && (
                    <>
                        <Form.Item name="type" label="Type de prix">
                            <Radio.Group>
                                <Radio.Button value="price">Produit (abo)</Radio.Button>
                                <Radio.Button value="addon">Option</Radio.Button>
                                <Radio.Button value="setup">Frais d'installation</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {type === "price" && (
                            <Form.Item
                                label="Tarification"
                                name="billingScheme"
                                rules={[{ required: true, message: "Ce champ est requis" }]}
                            >
                                <Select
                                    options={[
                                        { label: "Tarif standard", value: "per_unit" },
                                        { label: "Tarif au volume", value: "tiered" },
                                    ]}
                                />
                            </Form.Item>
                        )}
                        {billingScheme === "tiered" ? (
                            <Form.List name="tiers">
                                {(tiers, { add, remove }) => (
                                    <TiersFormTable tiersData={tiersData} tiers={tiers} add={add} remove={remove} />
                                )}
                            </Form.List>
                        ) : (
                            <Form.Item
                                label="Prix"
                                name="amount"
                                rules={[{ required: true, message: "Le prix est requis" }]}
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                        )}
                        {type !== "setup" && (
                            <>
                                <Form.Item name="recurring" label="Type de facturation">
                                    <Radio.Group>
                                        <Radio.Button value={true}>Récurrent</Radio.Button>
                                        <Radio.Button value={false}>Ponctuel</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                {recurring && (
                                    <Form.Item label="Interval de facturation" name="interval">
                                        <Select>
                                            <Select.Option value="month">Mensuel</Select.Option>
                                            <Select.Option value="year">Annuel</Select.Option>
                                        </Select>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </>
                )}
                {!modal && (
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                )}
            </Form>
        </Spin>
    );

    if (!modal) return content;

    return (
        <Modal
            title="Ajouter un prix a ce produit"
            visible
            onOk={() => form.submit()}
            onCancel={handleClose}
            okText="Créer"
            cancelText="Annuler"
        >
            {content}
        </Modal>
    );
}
