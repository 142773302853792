import React, {useContext} from 'react';
import {useImmer} from 'use-immer';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
const defaultState = {
    account: {},
    addons: [],
    brand: null,
    company: null,
    price: null,
    product: null,
    setup: null,
};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const CheckoutProvider = ({children}) => {
    const [state, dispatch] = useImmer({...defaultState});
    // alternatively without Immer:  const [state, dispatch] = useState({});

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

// ---------------------------------------------------
// Context usage function declaration
// ---------------------------------------------------
function useStateContext() {
    const state = useContext(StateContext);

    if (state === undefined) {
        throw new Error("Ut oh, where is my state?");
    }

    return state;
}

function useDispatchContext() {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    if (state === undefined) {
        throw new Error("Ut oh, where is my state?");
    }

    if (dispatch === undefined) {
        throw new Error("Ut oh, where is my dispatch?");
    }

    function setupCheckout(config) {
        dispatch(draft => {
            draft.product = config.product;
            draft.price = config.price;
            draft.setup = config.setup;
            draft.addons = config.addons;
        });
    }

    function setCompany(value) {
        dispatch(draft => {
            draft.company = value;
        });
    }

    function setAccount(value) {
        dispatch(draft => {
            draft.account = value;
        });
    }

    function setBrand(value) {
        dispatch(draft => {
            draft.brand = value;
        });
    }

    return {
        setCompany,
        setAccount,
        setBrand,
        setupCheckout
    };
}

const useCheckoutContext = () => {
    return [useStateContext(), useDispatchContext()]
};

export {useCheckoutContext, CheckoutProvider, StateContext, DispatchContext};
