import React from 'react'
import CollectionTable from '../../components/CollectionTable'
import dayjs from 'dayjs'


export default function StripeLogs() {

    const columns = [
        {
            title: 'Objet',
            dataIndex: 'object'
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Stripe id',
            dataIndex: 'stripeId',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            render: (createdAt) => <>{dayjs(createdAt).format('DD-MM-YYYY / HH:mm')}</>
        },
    ]

    return (
        <div>
            <h1>Logs stripe</h1>
            <CollectionTable endpoint={"webhooks_logs"} columns={columns} />
        </div>
    )
}
