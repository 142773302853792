import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiCollectionLatest from "../../components/ApiCollectionLatest";
import CardDash from "../../components/Card/CardDash";
import SubscriptionDash from "../../components/Subscription/SubscriptionDash";
import { useApiContext } from "../../providers/ApiProvider";

export default function Company() {
  const [apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    fetchCompany();
  }, []);

  const fetchCompany = async () => {
    setLoading(true);
    let response = await apiFetchEntity("companies", params.id);

    setCompany(response)
    setLoading(false);
  };



  return (
    <section >
      <h1>Société : {company?.name} </h1>
      <Spin spinning={loading}>
        <p>{company?.address}, {company?.city} - {company?.zipCode}, {company?.country}</p>
      </Spin>
      <Row gutter={[16]}>
        <Col span={12}>
          <CardDash companyId={params.id} />
        </Col>
        <Col span={12}>
          <SubscriptionDash fetch_endpoint={`companies/${params.id}/subscriptions`} companyId={params.id}/>
        </Col>

      </Row>


    </section>
  );
}
