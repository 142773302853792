import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Spin} from "antd";
import {useAuthContext} from "../providers/AuthProvider";
import {useApiContext} from "../providers/ApiProvider";
import ModalUpdatePassword from "../components/Modal/ModalUpdatePassword";

const Profile = () => {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiFetchEntity, apiUpdateEntity} = apiDispatch;

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        fetch()
    }, [needUpdate])


    async function fetch() {
        setLoading(true)
        const response = await apiFetchEntity('users', auth.id)
        form.setFieldsValue({
            firstname: response.firstname,
            lastname: response.lastname,
            email: response.email
        })
        setLoading(false)
    }

    async function onFinish(values) {
        setLoading(true)
        const response = await apiUpdateEntity('users', auth.id, values)
        setNeedUpdate(needUpdate + 1)
    }

    return (
        <div>
            <h1>Mes informations</h1>

            <Spin spinning={loading}>
                <Form
                    className="mt-2em"
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    form={form}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="Prénom"
                        name="firstname"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Nom"
                        name="lastname"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 4, span: 14}}>
                        <Button type="primary" htmlType="submit">
                            Enregistrer
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>

            <div className="mt-2em">
                <ModalUpdatePassword/>
            </div>

        </div>
    );
};

export default Profile;