import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom'

import { Button, Card, Form, Input, message } from 'antd';
import { useAuthContext } from "../providers/AuthProvider";
import { useTranslation } from "react-i18next";
import ModalForgetPassword from "../components/Modal/ModalForgetPassword";

export default function Login() {

    const { t } = useTranslation();
    const [authState, authDispatch] = useAuthContext();
    const { authLogin, authIsLogged } = authDispatch;
    const location = useLocation();

    const [processing, setProcessing] = useState(false);
    const isClientLogin = location.pathname === '/login';

    const onFinish = async (values) => {
        setProcessing(true)
        await authLogin(values.email, values.password, (response) => {
            if (response.error) {
                message.error(response.error)
                setProcessing(false)
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (authIsLogged())
        return authState.auth.roles.includes('ROLE_ADMIN') ? <Navigate to="/admin/dashboard" /> :
            <Navigate to="/dashboard" />

    return (
        <div className="login-page">
            <Card className="login-box">
                <div className="text-center mb-1em">
                    <h1>MMC payment</h1>
                </div>
                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <h2 className="form-title">Welcome back</h2>
                    <p>{isClientLogin ? t('Connexion à votre espace') : t('Connexion administrateur')}</p>

                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: t('Entrez votre email.') }]}
                    >
                        <Input placeholder='Adresse email' size="large"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t('Entrez un mot de passe.') }]}
                    >
                        <Input.Password placeholder='Mot de passe' size="large"/>
                    </Form.Item>

                    <div className="flex justify-end">
                        <ModalForgetPassword />
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={processing} className="login-form-button" size="large">
                            {t('CONNEXION')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};
