import React, { useEffect, useState } from 'react'
import ApiCollectionLatest from '../../components/ApiCollectionLatest'
import SubscriptionDash from '../../components/Subscription/SubscriptionDash'
import { useParams } from "react-router-dom";
import { useApiContext } from '../../providers/ApiProvider';
import { Col, Empty, message, Row, Spin, Typography } from 'antd';
import ItemList from '../../components/ItemList';
import { CopyOutlined } from '@ant-design/icons';

export default function Account() {

    const [apiDispatch] = useApiContext();
    const { apiFetchEntity } = apiDispatch
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const params = useParams()

    useEffect(() => {
        fetchAccount()
    }, [params.id])


    const fetchAccount = async () => {
        setLoading(true)
        let response = await apiFetchEntity('accounts', params.id)
        console.log('response', response)
        setLoading(false)
        if (response.id) return setAccount(response)

        return message.error(response['hydra:description']);
    }

    return (
        <Spin spinning={loading}>
            <h1>Client : {account?.name}</h1>
            <p>
                <Typography.Paragraph copyable >
                    {account?.stripeUserId}
                </Typography.Paragraph>
            </p>
            <Row gutter={[16]}>
                <Col span={6}>
                    <h3>Sociétés</h3>
                    <ItemList fetch_endpoint={`accounts/${params.id}/companies`} onClick={setSelected} selected={selected} />
                </Col>
                <Col span={18}>
                    {
                        selected ?
                            <>
                                <h3>Abonnements</h3>
                                <SubscriptionDash fetch_endpoint={`companies/${selected.id}/subscriptions`} />
                            </>
                            : <Empty />
                    }
                </Col>
            </Row>
        </Spin>
    )
}
