import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';

import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import AdminRoute from "./Routes/AdminRoute";

//page publique
import Login from "../pages/Login";
import Logout from "../pages/Logout";

// page client
import Dashboard from "../pages/private/Dashboard";


// page administration
import AdminDashboard from "../pages/admin/Dashboard";
import StripeProducts from "../pages/admin/StripeProducts";
import Contracts from "../pages/private/Contracts";
import Companies from "../pages/private/Companies";
import Invoices from "../pages/private/Invoices";
import Company from "../pages/private/Company";
import Contacts from "../pages/private/Contacts";
import Product from "../pages/admin/Product";
import Profile from "../pages/Profile";
import Checkout from "../pages/private/Checkout";
import Products from "../pages/admin/Products";
import CheckoutProcess from "../pages/CheckoutProcess";
import StripeLogs from "../pages/admin/StripeLogs";
import Account from "../pages/admin/Account";
import Coupons from "../pages/admin/Coupons";

export default function Router() {

    return (
        <Routes>
            <Route exact path="/login-admin" element={<PublicRoute component={Login} />} />
            <Route exact path="/login" element={<PublicRoute component={Login} />} />
            <Route exact path="/logout" element={<PublicRoute component={Logout} />} />
            <Route exact path="/subscription/:productId/:priceId" element={<PublicRoute component={CheckoutProcess} />} />

            {/* Private route should only be for regular users */}
            <Route exact path="dashboard" element={<PrivateRoute component={Dashboard} />} />
            <Route exact path="contacts" element={<PrivateRoute component={Contacts} />} />
            <Route exact path="contracts" element={<PrivateRoute component={Contracts} />} />
            <Route exact path="companies" element={<PrivateRoute component={Companies} />} />
            <Route exact path="profile" element={<PrivateRoute component={Profile} />} />
            <Route exact path="checkout/:productId/:product" element={<PrivateRoute component={Checkout} />} />
            <Route exact path="checkout/:productId/:product/:priceId" element={<PrivateRoute component={Checkout} />} />

            <Route path="company/:id" element={<PrivateRoute component={Company} />} >
                <Route exact path="invoices" element={<PrivateRoute component={Invoices} />} />
            </Route>

            {/* Admin route should only be for super users and start with admin */}

            <Route exact path="/admin/dashboard" element={<AdminRoute component={AdminDashboard} />} />
            <Route exact path="/admin/account/:id" element={<AdminRoute component={Account} />} />
            <Route exact path="/admin/stripe-products" element={<AdminRoute component={StripeProducts} />} />
            <Route exact path="/admin/products" element={<AdminRoute component={Products} />} />
            <Route exact path="/admin/coupons" element={<AdminRoute component={Coupons} />} />
            <Route exact path="/admin/products/:id" element={<AdminRoute component={Product} />} />
            <Route exact path="/admin/profile" element={<AdminRoute component={Profile} />} />
            <Route exact path="/admin/stripe/logs" element={<AdminRoute component={StripeLogs} />} />

            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    )
}
