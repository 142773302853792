import React from 'react'
import {Avatar, Card, Dropdown, Menu} from 'antd';
import {Link} from "react-router-dom";
import {useAuthContext} from "../../providers/AuthProvider";

import {useTranslation} from "react-i18next";


export default function AvatarDropdown() {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const isSuper = auth.roles.includes('ROLE_ADMIN');
    const {t, i18n} = useTranslation();

    const menu = () => {

        let items = [
            {label: <Link to="/logout">{t('logout')}</Link>, title: 'Deconnexion', key: 'logout'}
        ]
        if (isSuper) {
            items.unshift({label: <Link to="/admin/profile">{t('Mon profil')}</Link>, title: 'Mon Profil', key: 'profile'},)
        } else {
            items.unshift({label: <Link to="/profile">{t('Mon profil')}</Link>, title: 'Mon Profil', key: 'profile'},)
        }
        return (<Menu items={items}/>)
    }

    return (
        <Dropdown overlay={menu} trigger={['click']} placement="topRight">
            <Card>
                <Card.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                    title={`${auth.firstname || 'John'} ${auth.lastname || 'Doe'}`}
                    description={auth.email}
                />
            </Card>
        </Dropdown>
    )
}