import React, { useEffect, useState } from 'react'
import { Button, Drawer, Space } from 'antd'
import { useApiContext } from '../../providers/ApiProvider';
import PromotionCodesForm from './PromotionCodesForm';
import { getDurationText } from './helpers';


export default function Coupon({ couponId, onClose }) {

    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiUpdateEntity } = apiDispatch;
    const [coupon, setCoupon] = useState(null)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [isCreating, setIsCreating] = useState(null)

    useEffect(() => {
        if (couponId)
            fetchSub()
    }, [couponId, needUpdate])

    const fetchSub = async () => {
        // should fetch the sub and process data to be ready to be displayed
        const response = await apiFetchEntity('coupons', couponId)

        if (response['id']) {
            setCoupon(response)
        }
    }

    return (
        <Drawer title="Bon de réduction" placement="right" onClose={onClose} visible={couponId}>
            <Space direction='vertical'>
                <ul>
                    <li>Nom : {coupon?.name}</li>
                    <li>Valid : {coupon?.valid ? 'Oui' : 'Non'}</li>
                    <li>Validité :{getDurationText(coupon?.duration, coupon?.durationInMonths)}</li>
                    {
                        coupon?.maxRedemptions &&
                        <li>Nombre maximum d'utilisations : {coupon?.maxRedemptions}</li>
                    }
                    <li>Montant de réduction : {coupon?.type === 'percent' ? `${coupon?.percentOff}%` : coupon?.amountOff / 100 + ' ' + coupon?.currency}</li>
                    <li>Stripe Id : {coupon?.stripeCouponId}</li>
                </ul>

                <p> <Button onClick={() => setIsCreating({ id: 'new' })}>+</Button> Code Promo</p>
                {
                    coupon?.promotionCodes.map(item => item.code)
                }
            </Space>
            {
                isCreating &&
                <PromotionCodesForm promoCode={isCreating} coupon={coupon} modal handleClose={() => setIsCreating(null)} />
            }
        </Drawer>
    )
}
