import React, {useState} from 'react';
import {Button, Form, Input, message} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {useAuthContext} from "../../providers/AuthProvider";

const SubscriptionForm = ({setCurrentStep, setView}) => {

    const {t} = useTranslation();

    const [apiDispatch] = useApiContext();
    const {apiPostEntityPublic} = apiDispatch;
    const [authState, authDispatch] = useAuthContext();
    const {authLogin} = authDispatch;

    const [loading, setLoading] = useState(false);

    async function handleFinish(values) {
        setLoading(true);
        const response = await apiPostEntityPublic('subscription', values);
        console.log(response);
        if (!response.success) {
            setLoading(false)
            return message.error(response.codeError)
        }

        await authLogin(values.email, values.password, (response) => {
            if (response.error) {
                setLoading(false)
                return message.error(response.error)
            }
        });
        message.success('Profil enregistré')
        setLoading(false)
        setCurrentStep(1)
    }

    return (

        <div>
            <h2 style={{paddingBottom: 20, textAlign: "center"}}>Inscription</h2>

            <Form
                layout="vertical"
                onFinish={handleFinish}
            >

                <Form.Item
                    label={t('Prénom')}
                    name="firstname"
                    rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Nom')}
                    name="lastname"
                    rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Email')}
                    name="email"
                    rules={[
                        {type: 'email', message: 'Email invalide'},
                        {required: true, message: 'Ce champs est obligatoire'}
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Téléphone')}
                    name="phone"
                    rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Mot de passe"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirmez votre mot de  passe"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        S'inscrire
                    </Button>
                    <Button type="link" onClick={() => setView('login')}>J'ai déjà un compte</Button>
                </Form.Item>

            </Form>

        </div>
    );
};

export default SubscriptionForm;