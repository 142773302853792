import React, { useEffect, useState } from 'react'
import { Button, Card, Drawer, message, Space, Tag } from 'antd'
import { useApiContext } from '../../providers/ApiProvider';
import { EditOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CreditCard from '../Card/CreditCard';
import SubscriptionPaymentUpdate from './SubscriptionPaymentUpdate';
import { useParams } from 'react-router-dom';
import PauseAction from './PauseAction';
import StripeContainer from '../Stripe/StripeContainer';

const statusIcons = {
    paused: <PauseCircleOutlined />,
    active: <PlayCircleOutlined />,
    canceled: <StopOutlined />
}

export default function Subscription({ subscriptionId, onClose }) {

    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiUpdateEntity } = apiDispatch;
    const [subscription, setSubscription] = useState(null)
    const [subPrice, setSubPrice] = useState(0)
    const [mainPrice, setMainPrice] = useState(null)
    const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false)
    const params = useParams();
    const [needUpdate, setNeedUpdate] = useState(0)
    const [actionLoading, setActionLoading] = useState(false)

    useEffect(() => {
        if (subscriptionId)
            fetchSub()
    }, [subscriptionId, needUpdate])

    const fetchSub = async () => {
        // should fetch the sub and process data to be ready to be displayed
        const response = await apiFetchEntity('subscriptions', subscriptionId)

        if (response['id']) {
            setSubscription(response)
            parseSubPrices(response.subscriptionItems)
        }
    }

    const parseSubPrices = (subItems) => {
        setMainPrice(subItems.find(item => item?.price?.type === 'price').price)
        setSubPrice(calculateTotal(subItems))
    }

    const calculateTotal = (items) => {
        return items.reduce((prev, current) => {
            let currentPrice = current.price
            if (currentPrice.billingScheme === 'per_unit') {
                return prev + currentPrice.amount;
            }

            let amount = 0;
            // récupération du tier correspondant pour calculer le prix
            let tiers = currentPrice.tiers;
            let correspondingTier = tiers.find(tier => tier.up_to !== 'inf' && tier.up_to >= current.quantity)
            if (correspondingTier) {
                amount = correspondingTier.flat_amount + correspondingTier.unit_amount * current.quantity
            } else {
                // si aucun tiers ne correspond, on utilise le dernier
                let infTier = tiers.find(tier => tier.up_to === 'inf' || !tier.up_to)
                amount = infTier.flat_amount + infTier.unit_amount * quantity
            }
            return prev + amount

        }, 0)

    }



    const handleCancel = async () => {
        setActionLoading(true)
        let params = {
            cancel_at_period_end: true
        }
        let response = await apiUpdateEntity('subscriptions', subscriptionId, params)
        console.log('response', response)
        setActionLoading(false)
        if (response['@type'] === "hydra:Error") {
            return message.error(response['hydra:description'])
        }

        setNeedUpdate((new Date()).getTime())
        return message.success('Abonnement annulé avec succes')
    }



    return (
        <Drawer title={<>{statusIcons[subscription?.status]} {subscription?.name}</>} placement="right" onClose={onClose} visible={subscriptionId}>
            <Space direction='vertical'>
                <p>Gérer votre abonnement</p>
                {
                    subscription?.status === 'paused' && <p>Votre abonnement est suspendu jusqu'au : {dayjs(subscription.pauseResumeAt).format('DD/MM/YYYY')}</p>
                }
                <Card title={<>{mainPrice?.name} <Tag color="green" >{subPrice}{mainPrice?.currency} {mainPrice?.reccuring && mainPrice?.recurringInterval}</Tag></>}
                    extra={<a href="#"><EditOutlined /></a>}
                    style={{ width: 300 }}>
                    <p>Date de création: {dayjs(subscription?.createdAt).format('DD/MM/YYYY')}</p>
                    <p>Prochain paiement: {dayjs(subscription?.currentPeriodEnd).format('DD/MM/YYYY')}</p>
                    <p>Card content</p>
                </Card>

                <Card title="Moyen de paiement" extra={<a href="#"><EditOutlined onClick={() => setUpdatePaymentMethod(true)} /></a>} style={{ width: 300 }}>
                    <p>Modifier votre moyen de paiement</p>
                    <CreditCard card={subscription?.card} />
                </Card>

                <p>Tableau facture (not ready yet)</p>

                <div>
                    <PauseAction subscription={subscription} setActionLoading={setActionLoading} actionLoading={actionLoading} setNeedUpdate={setNeedUpdate} />
                    <Button loading={actionLoading} onClick={handleCancel} >Terminer l'abonnement</Button>
                </div>
            </Space>
            <StripeContainer>

                <SubscriptionPaymentUpdate companyId={params.id} subscription={subscription} visible={updatePaymentMethod} setVisible={setUpdatePaymentMethod} />
            </StripeContainer>
        </Drawer>
    )
}
