import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Radio, Select, Space, Spin } from 'antd';
import { useApiContext } from '../providers/ApiProvider';
import { useAuthContext } from '../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const options = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d",
            },
        },
        invalid: {
            fontFamily: "Arial, sans-serif",
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function CardForm({ handleCard, selectedCompany, total }) {

    const { t } = useTranslation();
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResource, apiPostEntity, apiUpdateEntity } = apiDispatch;
    const [authState] = useAuthContext();
    const { auth } = authState;
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();
    const newCard = Form.useWatch('newCard', form);

    const stripe = useStripe();
    const elements = useElements();


    useEffect(() => {
        fetchCards();
    }, [selectedCompany]);

    const fetchCards = async () => {
        console.log("account", auth);

        setLoading(true);
        const response = await apiFetchSubResource(
            "companies",
            { id: selectedCompany.id },
            "cards"
        );

        if (response["hydra:member"]) {
            let data = response["hydra:member"];
            if (!data.length) form.setFieldsValue({newCard: true})
            setCards(data);
        }

        setLoading(false);
    };

    async function handleSubmit(values) {
        console.log('values', values)
        setLoading(true)
        let card = null;
        if (values.newCard) {
            card = await createCard(values);
        } else {
            card = cards.find(item => item.id === values.cardId)
        }
        console.log('card', card)
        await handleCard(card);

        setLoading(false)
    }

    const createCard = async (values) => {

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        message.error(error)
        if (error) return null;

        let data = {
            name: 'Carte de test',
            company: "api/companies/" + selectedCompany.id,
            stripeId: paymentMethod.id,
            lastFour: paymentMethod.card.last4,
            expMonth: paymentMethod.card.exp_month,
            expYear: paymentMethod.card.exp_year,
            brand: paymentMethod.card.brand,
            customer: paymentMethod.customer,
            name: values.name
        };

        const response = await apiPostEntity("cards", data);

        if (response['@type'] === "hydra:Error") {
            message.error(response['hydra:description']);
        }
        console.log('response', response)
        return response;
    };


    return (
        <Spin spinning={loading}>
            <h2>Information de paiement</h2>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ newCard: false }}
            >
                <Form.Item name="newCard" hidden={!cards.length}>
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={false}>Choisir une carte existante</Radio>
                            <Radio value={true}>Ajouter une nouvelle carte</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {
                    !!cards.length && !newCard &&
                    <>
                        <Form.Item
                            label={t('Carte')}
                            name="cardId"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Select
                                style={{ width: 250 }}
                                placeholder="Selectionner une carte"
                            >
                                {
                                    cards.map((card) => (
                                        <Select.Option key={card.id} value={card.id} title={card.brand}>
                                            {/* {card.name} **** **** **** {card.lastFour} - */}
                                            <div className='bold'><span className='capitalize'>{card?.brand}</span> terminant par: {card?.lastFour}</div>
                                            <div>Expire le {card?.expMonth}/{card?.expYear}</div>
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </>
                }
                {
                    (newCard || !cards.length) &&
                    <>
                        <div id="messages"></div>
                        <Form.Item
                            label={t('Nom de votre carte')}
                            name="name"
                            rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>

                        <CardElement options={options} />
                        <p>Card : 4242424242424242</p>
                        <p>Card verif : 4000002500003155</p>
                    </>
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Valider {total}€
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}
