import React, { useEffect, useState } from "react";
import { Button } from "antd";

import ApiCollectionLatest from "../ApiCollectionLatest";
import CardForm from "./CardForm";
import StripeContainer from "../Stripe/StripeContainer";

export default function CardDash({ companyId }) {
  
    const [isCreating, setIsCreating] = useState(false);

  const colums = [
    {
      dataIndex: "stripeId",
      key: "stripeId",
      title: "4 dernier",
    },
    {
      dataIndex: "lastFour",
      key: "lastFour",
      title: "4 dernier",
    },
    {
      dataIndex: "brand",
      key: "brand",
      title: "Brand",
    },
    {
      dataIndex: "expMonth",
      key: "expMonth",
      title: "Expiration",
      render: (expMonth, record) => <>{expMonth}/{record.expYear}</>,
    },
  ];

  return (
    <>
      <ApiCollectionLatest
        fetch_endpoint={`companies/${companyId}/cards`}
        columns={colums}
        title={() => (
          <>
            Moyen de paiement
          </>
        )}
      />
      {
        <StripeContainer>
          <CardForm companyId={companyId} visible={isCreating} handleClose={() => setIsCreating(null)}/>
        </StripeContainer>
      }
    </>
  );
}
