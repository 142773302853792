import React, {useState} from 'react';
import {Form, Input, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

const ModalUserPermission = ({visible, setVisible, user, reload, accountId}) => {

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch;

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                if (user)
                    update(values)
                else
                    create(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    async function create(values) {
        setLoading(true)
        values.accountId = accountId
        values.password = `test`
        values.roles = ['ROLE_USER']
        const response = await apiPostEntity('users', values)
        reload()
        form.resetFields();
        setLoading(false)
        setVisible(false)
    }

    async function update(values) {
        setLoading(true)
        const response = await apiUpdateEntity('users', user.id, values)
        reload()
        form.resetFields();
        setLoading(false)
        setVisible(false)
    }

    return (
        <Modal
            confirmLoading={loading}
            destroyOnClose
            title={'Ajouter une permission'}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={handleSubmit}
        >

            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    firstname: user ? user.firstname : '',
                    lastname: user ? user.lastname : '',
                    email: user ? user.email : ''
                }}
            >

                <Form.Item
                    label="Prénom"
                    name="firstname"
                    rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nom"
                    name="lastname"
                    rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ type: 'email', required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Input/>
                </Form.Item>

            </Form>

        </Modal>
    );
};

export default ModalUserPermission;