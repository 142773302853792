import { GlobalOutlined } from '@ant-design/icons'
import React from 'react'

export default function BrandLogo() {
    return (
        <div style={{ height: '2em', margin: '1em' }}>
            <h2><GlobalOutlined /> MMC payment</h2>
        </div>
    )
}
