import React from 'react'
import { CreditCardOutlined } from '@ant-design/icons'

export default function CreditCard({ card, selectable = false, selected = false, style = {}, onSelect = () => {} }) {
    return (
        <div 
        key={card?.id}
        className={`cb-card ${selectable && 'selectable'} ${selectable && selected && 'selected'}`} 
        onClick={() => onSelect(card)}
        style={{...style}}
        >
            {
                selectable &&
                <div></div>
            }
            <div className="icon">
                <CreditCardOutlined />
            </div>
            <div>
                <div className='bold'><span className='capitalize'>{card?.brand}</span> terminant par: {card?.lastFour}</div>
                <div>Expire le {card?.expMonth}/{card?.expYear}</div>
            </div>
        </div>
    )
}
