import React, { useEffect, useState } from 'react'
import { Button, Card, Segmented, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom'
import PriceForm from '../../components/Stripe/PriceForm';
import { useApiContext } from '../../providers/ApiProvider';
import { EditOutlined, ShoppingCartOutlined } from '@ant-design/icons';

export default function Product() {

    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiFetchSubResource } = apiDispatch;
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)
    const [priceDisplayed, setPriceDisplayed] = useState('price')
    const [selectedPrice, setSelectedPrice] = useState(null)
    const [needUpdate, setNeedUpdate] = useState(0)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        fetchProduct()
    }, [needUpdate])

    const fetchProduct = async () => {
        setLoading(true)
        let response = await apiFetchEntity('products', params.id)
        setProduct(response)
        setLoading(false)
    }

    const handleClose = (reload) => {
        if (reload) setNeedUpdate((new Date()).getTime())
        setSelectedPrice(null)
    }

    return (
        <div>
            <h2>Produit {product?.name}</h2>
            <p>{product?.description}</p>
            <p>Période d'essai: {product?.trialPeriod} jours</p>
            <h3>Prix</h3>

            <Spin spinning={loading}>
                <Button onClick={() => setSelectedPrice({ id: 'new' })} >Add price</Button>
                <Segmented
                    options={[
                        { label: 'Abonnement', value: 'price' },
                        { label: 'Options', value: 'addon' },
                        { label: 'Mise en place', value: 'setup' }]}
                    value={priceDisplayed} onChange={setPriceDisplayed} />

                <div className='flex wrap'>
                    {
                        product?.prices.filter(item => item.type === priceDisplayed).map(price =>
                            <Card style={{ width: 300 }} title={price.name} key={price.id}
                                extra={
                                    <>
                                        <Button onClick={() => setSelectedPrice(price)} icon={<EditOutlined />} />
                                        <Button icon={<ShoppingCartOutlined />} onClick={() => navigate(`/subscription/${product.id}/${price.id}`)} />
                                    </>
                                }>
                                {
                                    price.billingScheme === 'per_unit' ?
                                        <p>{price.amount} {price.currency} {price.recurring && `/ ${price.recurringInterval}`}</p>
                                        :
                                        <div>
                                            {
                                                price.tiers.map((item, index) => {
                                                    let from = index === 0 ? 0 : price.tiers[index - 1].up_to + 1
                                                    return (
                                                        <p>Si la quantité est comprise entre {from} et {item.up_to === 'inf' ? 'plus' : item.up_to} : {item.unit_amount}/unité € + {item.flat_amount} €</p>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </Card>
                        )
                    }
                </div>
            </Spin>
            {
                selectedPrice &&
                <PriceForm price={selectedPrice} product={product} modal handleClose={handleClose} />
            }
        </div>
    )
}
