import {CardElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button, Card, Form, message, Spin} from 'antd';
import React, {useState} from 'react'
import {useApiContext} from "../../providers/ApiProvider";
import {useCheckoutContext} from "../../providers/CheckoutProvider";
import {useParams} from "react-router-dom";
import CardSelection from "../Checkout/CardSelection";

const options = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
}

export default function StripeElements({subscriptionData, handleSuccess}) {

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const {brand, company} = checkoutState;

    // const productId = 1;
    // const priceId = 1;

    const params = useParams()


    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;

    const stripe = useStripe();
    const elements = useElements()

    if (!stripe && !elements) {
        return <Spin spinning={true}/>
    }
    const handleSubmit = async (values) => {
        const cardElement = elements.getElement(CardElement);
        let response;
        if (subscriptionData.pending_setup_intent) {
            response = await handleCardSetup(cardElement)
        } else {
            response = await handlePayment(cardElement)
        }

        if (response.error) {
            return message.error(response.error.message)
        }
        console.log('response', response.data)

        if (response.data.status === 'succeeded') {
            message.success('Inscription validé avec succes');
            handleSuccess(response.data)
            let now = new Date()
            const responseApi = await apiPostEntity('subscriptions', {
                stripeSubId: subscriptionData.id,
                status: 'active',
                prices: [params.priceId],
                companyId: company['id'],
                brandId: brand['id'],
                paymentMethodId: response.data.payment_method,
                stripeItems: subscriptionData.items.data,
                createdAt: new Date(),
                currentPeriodStart: new Date(),
                currentPeriodEnd: new Date(now.setMonth(now.getMonth() + 1))
            })

            console.log(responseApi)
        }
    }

    console.log(subscriptionData)

    const handlePayment = async (cardElement) => {
        let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret

        const data = {
            payment_method: selectedCard || {
                card: cardElement,
                billing_details: {
                    name: 'Dave',
                }
            }
        }

        let {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, data)
        return {error: error, data: paymentIntent}
    }

    const handleCardSetup = async (cardElement) => {
        let clientSecret = subscriptionData.pending_setup_intent.client_secret

        const data = {
            payment_method: selectedCard || {
                card: cardElement,
                billing_details: {
                    name: 'Dave',
                }
            }        }

        let {error, setupIntent} = await stripe.confirmCardSetup(clientSecret, data)
        return {error, data: setupIntent}

    }

    const [selectedCard, setSelectedCard] = useState('')

    return (
        <Card className="login-box" style={{maxWidth: '600px'}}>
            <h2>S'abonner</h2>

            <CardSelection selectedCard={selectedCard} setSelectedCard={setSelectedCard}/>

            <h3>Ou payer avec une nouvelle carte</h3>

            <p>Card : 4242424242424242</p>
            <p>Card verif : 4000002500003155</p>
            <p>Card error: 4000 0000 0000 9995</p>
            <Form id="subscribe-form" onFinish={handleSubmit}>

                <CardElement options={options}/>
                {/* <PaymentElement options={options} /> */}

                <Button htmlType="submit">
                    Subscribe
                </Button>

                <div id="messages"></div>
            </Form>
        </Card>
    )
}
