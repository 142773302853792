import React, {useState} from 'react';
import {Button, Form, Modal, Input, message} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {useAuthContext} from "../../providers/AuthProvider";

const ModalUpdatePassword = () => {

    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [apiDispatch] = useApiContext();
    const {apiPostEntityPublic, apiUpdateEntity} = apiDispatch;
    const [authState] = useAuthContext();
    const {auth} = authState;

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    function handleConfirm() {
        form
            .validateFields()
            .then(async (values) => {
                setLoading(true)
                const response = await apiPostEntityPublic('login', {username: auth.email, password: values.oldPassword})
                if (response.token){
                    const response = await apiUpdateEntity('users', auth.id, {password: values.newPassword})
                    setLoading(false)
                    setVisible(false)
                    form.resetFields()
                    message.success(t('Mot de passe modifier avec succès'))
                } else {
                    setLoading(false)
                    message.error(t('Mot de passe incorrect'))
                }
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <>
            <Button type="danger" onClick={() => setVisible(true)}>Modifier mon mot de passe</Button>

            <Modal
                confirmLoading={loading}
                title={t('Modifier mon mot passe')}
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={handleConfirm}
            >

                <Form
                    layout="vertical"
                    autoComplete="off"
                    form={form}
                >

                    <Form.Item
                        name="oldPassword"
                        label={t("Ancien mot de passe")}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="newPassword"
                        label={t("Nouveau mot de passe")}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('oldPassword') !== value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('Le nouveau mot de passe doit etre different de l\'ancien')));
                                },
                            })
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        name="confirmNewPassword"
                        label={t("Confirmer nouveau mot de passe")}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            })
                        ]}
                        hasFeedback
                    >
                        <Input.Password/>
                    </Form.Item>

                </Form>

            </Modal>

        </>
    );
};

export default ModalUpdatePassword;