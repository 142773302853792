import React, { useState } from 'react';
import { useApiContext } from "../../providers/ApiProvider";
import CardForm from '../CardForm';
import { useCheckoutContext } from '../../providers/CheckoutProvider';
import { useStripe } from "@stripe/react-stripe-js";
import { Button, message, Result, Space } from 'antd';
import Price from './Price';

const Payment = () => {

    const [apiDispatch] = useApiContext();
    const { apiPostEntity } = apiDispatch;
    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const { price, product, addons, setup, company, account, brand } = checkoutState;
    const [paymentIntent, setPaymentIntent] = useState(null)
    const stripe = useStripe();
    const [total, setTotal] = useState(0)
    const [priceQuantity, setPriceQuantity] = useState(1)


    const handleSuccess = (paymentIntent) => {
        setPaymentIntent(paymentIntent)
    }

    const handleCard = async (card) => {

        let defaultPaymentMethod = card.stripeId
        console.log('card', card)
        const subscriptionStripe = await createSubscription(defaultPaymentMethod)
        console.log('response', subscriptionStripe)

        let response;
        if (subscriptionStripe.pending_setup_intent) {
            response = await handleCardSetup(subscriptionStripe.pending_setup_intent.client_secret, defaultPaymentMethod)
        } else {
            response = await handlePayment(subscriptionStripe.latest_invoice.payment_intent.client_secret, defaultPaymentMethod)
        }
        console.log('response1', response)
        if (response.error) {
            return message.error(response.error.message)
        }
        console.log('response', response.data)

        if (response.data.status === 'succeeded') {
            message.success('Inscription validé avec succes');
            handleSuccess(response.data)
            const responseApi = await createSubscriptionMMCAPI(subscriptionStripe, response)
        }
    }


    const createSubscription = async (defaultPaymentMethod) => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret
        const data = {
            name: product.name,
            priceId: price?.stripePriceId || null,
            trial: product.trialPeriod,
            setup: setup || null,
            quantity: priceQuantity,
            customerId: account.stripeUserId,
            defaultPaymentMethod: defaultPaymentMethod
        }

        const response = await apiPostEntity('stripe/subscription', data)

        return response.subscription
    }

    const handlePayment = async (clientSecret, paymentMethod) => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret

        const data = {
            payment_method: paymentMethod
        }

        let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, data)
        return { error: error, data: paymentIntent }
    }

    const handleCardSetup = async (clientSecret, paymentMethod) => {
        // let clientSecret = subscriptionData.pending_setup_intent.client_secret

        const data = {
            payment_method: paymentMethod
        }

        let { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, data)
        return { error, data: setupIntent }
    }

    const createSubscriptionMMCAPI = async (subscriptionStripe, paymentResponse) => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret

        let now = new Date()
        const response = await apiPostEntity('subscriptions', {
            name: product.name,
            stripeSubId: subscriptionStripe.id,
            status: 'active',
            prices: [price.id],
            companyId: company['id'],
            brandId: brand['id'],
            paymentMethodId: paymentResponse.data.payment_method,
            stripeItems: subscriptionStripe.items.data,
            createdAt: new Date(),
            currentPeriodStart: new Date(),
            currentPeriodEnd: new Date(now.setMonth(now.getMonth() + 1))
        })

        console.log(response)
    }

    if (paymentIntent) {
        return (
            <div>
                <Result
                    status="success"
                    title="Vous avez souscris un abonnement avec succes!"
                    subTitle="La configuration de votre abonnement est encore, merci de patienter."
                    extra={[
                        <Button type="primary" key="console">
                            Se connecter
                        </Button>,
                        <Button key="buy">Soucrire un nouvel abonnement</Button>,
                    ]}
                />
            </div>
        )
    }

    return (
        <>
            <Price setTotal={setTotal} setPriceQuantity={setPriceQuantity}/>
            <CardForm selectedCompany={company} handleCard={handleCard} total={total}/>
        </>
    )
};

export default Payment;