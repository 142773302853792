import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { useApiContext } from '../../providers/ApiProvider'
import ProductsTable from '../../components/Stripe/ProductsTable';
import ProductForm from '../../components/Stripe/ProductForm';

export default function StripeProducts() {

    const [apiDispatch] = useApiContext();
    const { apiFetchSubResource } = apiDispatch;

    const [prices, setPrices] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loadingPrices, setLoadingPrices] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (selectedProduct)
            fetchProductPrices()
    }, [selectedProduct])



    // get prices from stripe product id
    const fetchProductPrices = async () => {
        setLoadingPrices(true)
        const response = await apiFetchSubResource('stripe/products', { id: selectedProduct.id }, 'prices')
        console.log('response', response)
        setPrices(response.prices.data)
        setLoadingPrices(false)
    }

    const handleCreate = () => {
        setIsCreating(true)
    }

    const handleClose = () => {
        setIsCreating(false)
    }

    return (
        <>
            <h1>Produit Stripe</h1>
            <div>
                <Button onClick={handleCreate}>Créer un produit</Button>
            </div>
            <ProductsTable />

            {
                !!prices.length &&
                <>
                    <h2>Prix : {selectedProduct.name}</h2>
                    {
                        prices.map(price =>
                            <div>
                                {price.unit_amount / 100} {price.currency}
                            </div>
                        )}
                </>
            }

            {
                isCreating &&
                <ProductForm modal={true} handleClose={handleClose}/>
            }


        </>
    )
}
