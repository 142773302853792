import React, { useEffect, useState } from 'react'
import { Card, InputNumber, Space } from 'antd';
import { useCheckoutContext } from '../../providers/CheckoutProvider';

export default function Price({ setTotal, setPriceQuantity }) {

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const { price, product, addons, setup } = checkoutState;
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [quantity, setQuantity] = useState(1)

    useEffect(() => {
        if (price)
            calculateTotal()
    }, [selectedAddons, quantity, price])

    const calculateTotal = () => {
        let amount = 0;

        if (price.billingScheme === 'per_unit') {
            amount += price.amount
            console.log('amount', amount)
        } else {
            amount += calculatePrice()
        }
        amount += selectedAddons.reduce((prev, current) => {
            return prev += current.amount
        }, 0)

        amount += setup?.amount || 0

        setTotal(amount)
        setPriceQuantity(quantity)
    }

    const calculatePrice = () => {
        let tiers = price.tiers;
        let correspondingTier = tiers.find(tier => tier.up_to !== 'inf' && tier.up_to >= quantity)
        if (correspondingTier) {
            return correspondingTier.flat_amount + correspondingTier.unit_amount * quantity
        } else {
            let infTier = tiers.find(tier => tier.up_to === 'inf' || !tier.up_to)
            return infTier.flat_amount + infTier.unit_amount * quantity
        }
    }

    return (
        <div>
            <h2>Votre abonnement {product?.name}</h2>
            <Card key={price.id}>
                <h3>{price.name}</h3>
                <p>{price?.description}</p>
                {
                    price.billingScheme === 'per_unit' ?
                        <p>{price.amount} {price.currency}</p>
                        :
                        <Space>
                            <InputNumber min={1} value={quantity}
                                onChange={value => setQuantity(value)} />
                            Prix a payé : {calculatePrice()} €
                            <span>{price.reccuring && `Par ${price.recurringInterval === 'month' ? 'mois' : 'an'}. `}</span>

                        </Space>
                }
                {
                    setup && <p>{setup?.name} : {setup.amount}€</p>
                }
            </Card>
            {/* {
                !!addons.length &&
                <>
                    <h3>Options :</h3>
                    {
                        addons.map(item =>
                            <div>
                                <p>{item.name}</p>
                            </div>
                        )
                    }
                </>
            } */}

        </div>
    )
}
