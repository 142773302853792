import React from 'react'
import { useTranslation } from "react-i18next";
import AccountList from "../../components/account/AccountList";

export default function Dashboard() {

    const { t } = useTranslation();

    return (
        <>
            <h1>Welcome to Back Admin</h1>

            <AccountList />
        </>
    )
}