import React from 'react'
import { Layout, Menu } from 'antd';
import { Link, useLocation } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import { useTranslation } from "react-i18next";
import { ContactsOutlined, ContainerOutlined, HomeOutlined, ShopOutlined } from "@ant-design/icons";
import BrandLogo from './BrandLogo';

const { Content, Sider } = Layout;

export default function PrivateLayout({ children }) {

    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Layout className="mainLayout">
            <Sider width={300} style={{
                overflow: 'auto', height: '100vh', backgroundColor: 'white',
                position: 'fixed', left: 0, top: 0, bottom: 0
            }}>
                <BrandLogo />
                <Menu selectedKeys={[location.pathname]} items={[
                    { label: <Link to="/dashboard">{t('dashboard')}</Link>, title: 'Dashboard', key: '/dashboard', icon: <HomeOutlined /> },
                    { label: <Link to="/contracts">{t('abonnements')}</Link>, title: 'Abonnements', key: '/contracts', icon: <ContainerOutlined /> },
                    { label: <Link to="/companies">{t('sociétés')}</Link>, title: 'Sociétés', key: '/companies', icon: <ShopOutlined /> },
                    { label: <Link to="/contacts">{t('contacts')}</Link>, title: 'Contacts', key: '/contacts', icon: <ContactsOutlined /> },
                ]} />
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <AvatarDropdown />
                </div>
            </Sider>
            <Layout style={{ marginLeft: 300 }}>
                <Content className="flex">
                    <Layout className="site-layout-background">
                        <Content style={{ padding: '2em', minHeight: '100vh' }}>
                            {children}
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        </Layout>
    )
}