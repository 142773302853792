import React, {useState} from 'react';
import {Button, Form, Input, message} from "antd";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../providers/AuthProvider";

const Login = ({setCurrentStep, setView}) => {

    const {t} = useTranslation();

    const [authState, authDispatch] = useAuthContext();
    const {authLogin} = authDispatch;

    const [loading, setLoading] = useState(false);

    async function handleFinish(values) {
        setLoading(true);

        await authLogin(values.email, values.password, (response) => {
            if (response.error) {
                setLoading(false)
                return message.error(response.error)
            }
        });

        message.success('Connection réussi')
        setLoading(false)
        setCurrentStep(1)
    }

    return (

        <div>
            <h2 style={{paddingBottom: 20, textAlign: "center"}}>Inscription</h2>

            <Form
                layout="vertical"
                onFinish={handleFinish}
            >

                <Form.Item
                    label={t('Email')}
                    name="email"
                    rules={[
                        {type: 'email', message: 'Email invalide'},
                        {required: true, message: 'Ce champs est obligatoire'}
                    ]}
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    name="password"
                    label="Mot de passe"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Se connecter
                    </Button>
                    <Button type="link" onClick={() => setView('subscription')}>Je n'ai pas de compte</Button>
                </Form.Item>

            </Form>

        </div>
    );
};

export default Login;