import React, { useEffect, useState } from 'react'
import { Button, Space, Spin, Table } from 'antd'
import { useApiContext } from '../../providers/ApiProvider';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export default function ProductsTable() {

    const navigate = useNavigate()

    const [apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchProducts()
    }, [])

    // get products from stripe
    const fetchProducts = async () => {
        const response = await apiFetchCollection('stripe/products', {})
        console.log('response', response)
        setProducts(response.products.data)
        setLoading(false)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Actif',
            dataIndex: 'active',
            key: 'active',
            render: active => active ? <>Actif</> : <>Inactif</>

        },
        {
            title: 'Création',
            dataIndex: 'created',
            key: 'created',
            render: created => <>{dayjs.unix(created).format('DD-MM-YYYY')}</>
        },
        {
            title: 'Mise à jour',
            dataIndex: 'updated',
            key: 'updated',
            render: updated => <>{dayjs.unix(updated).format('DD-MM-YYYY')}</>
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: id => <Space>
                <Button onClick={() => navigate(`/admin/stripe-products/${id}`)}>+</Button>
                {/* <Button>checkout</Button> */}
            </Space>
        },
    ]

    return (
        <Spin spinning={loading}>
            <Table dataSource={products} columns={columns} rowKey="id"/>
        </Spin>
    )
}
