import React, { useState } from 'react'
import { Button, message, Modal } from 'antd'
import CollectionTable from '../../components/CollectionTable';
import CouponForm from '../../components/coupon/CouponForm';
import Coupon from '../../components/coupon/Coupon';
import { getDurationText } from '../../components/coupon/helpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useApiContext } from '../../providers/ApiProvider';
const {confirm} = Modal;

export default function Coupons() {

    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch;
    const [isCreating, setIsCreating] = useState(false);
    const [reload, setReload] = useState(0);
    const [selected, setSelected] = useState(null);

    const handleCreate = () => {
        setIsCreating(true)
    }

    const handleClose = (shouldReload) => {
        setIsCreating(false)
        if (shouldReload) {
            setReload((new Date()).getTime())
        }
    }

    const confirmDelete = (e, id) => {
        e.stopPropagation()
        confirm({
            title: 'Etes-vous sûr de vouloir supprimer ce coupon ?',
            icon: <ExclamationCircleOutlined />,
            content: "La suppression de ce bon de réduction n'affectera pas les remises pour les clients qui l'ont déjà utilisé, mais il ne pourra plus être utilisé.",
            okText: 'Oui',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    const response = await apiDeleteEntity('coupons', id);
                    if (response["@type"] === "hydra:Error") {
                        message.error(response['hydra:description'])
                    }
                    setReload((new Date()).getTime())
                    resolve()
                }).catch(() => console.log('Oops errors!'));

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Stripe id',
            dataIndex: 'stripeCouponId',
            key: 'stripeCouponId',
        },
        {
            title: 'Durée',
            dataIndex: 'duration',
            key: 'duration',
            render: (duration, record) => getDurationText(duration, record.durationInMonths)
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            render: (id) => <>
                <Button onClick={(e) => confirmDelete(e, id)}>del</Button>
            </>
        }
    ]


    return (
        <>
            <h1>Coupons</h1>
            <div>
                <Button onClick={handleCreate}>Créer un coupon</Button>
            </div>
            <CollectionTable endpoint="coupons" columns={columns} reload={reload} onClick={record => setSelected(record.id)} />
            {
                isCreating &&
                <CouponForm modal={true} handleClose={handleClose} coupon={{ id: 'new' }} />
            }
            <Coupon couponId={selected} onClose={() => setSelected(null)} />

        </>
    )
}
