import React, {useState} from 'react';
import {Form, Input, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

const ModalAccount = ({visible, setVisible, account, reload}) => {

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch;

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                if (account)
                    update(values)
                else
                    create(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    async function create(values) {
        setLoading(true)
        const response = await apiPostEntity('accounts', values)
        reload()
        form.resetFields();
        setLoading(false)
        setVisible(false)
    }

    async function update(values) {
        setLoading(true)
        const response = await apiUpdateEntity('accounts', account.id, values)
        reload()
        form.resetFields();
        setLoading(false)
        setVisible(false)
    }

    return (
        <Modal
            confirmLoading={loading}
            destroyOnClose
            title={'Ajouter un compte'}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={handleSubmit}
        >

            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: account ? account.name : ''
                }}
            >

                <Form.Item
                    label="Nom"
                    name="name"
                    rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                >
                    <Input/>
                </Form.Item>

            </Form>

        </Modal>
    );
};

export default ModalAccount;