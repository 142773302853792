import React, {useContext} from 'react';
import {useImmer} from 'use-immer';
import {useAuthContext} from './AuthProvider';
import axios from "axios";

const api_endpoint = process.env.API_ENTRYPOINT + '/api/';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
const defaultState = {};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const DispatchContext = React.createContext();

const ApiProvider = ({children}) => {
  const [dispatch] = useImmer({...defaultState});
  // alternatively without Immer:  const [state, dispatch] = useState({});

  return (
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
  );
};

function useDispatchContext() {
  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const {authLogout} = authDispatch;
  const authToken = auth ? auth.token : 'none';
  const dispatch = useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error("Ut oh, where is my dispatch?");
  }

  function responseAuthControl(api_response) {
    if (
        (api_response.error && api_response.error === "AuthenticationFailure") ||
        (api_response.message && api_response.message === "Username could not be found.") ||
        (api_response.message && api_response.message === "No API token provided") 
    ) {
      authLogout();
      return false;
    }
    return true;
  }

  async function apiFetchEntity(endpoint, id) {
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data
    }
  }

  async function apiFetchEntityPublic(endpoint, id) {
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id,
        headers: {
          'Accept': 'application/ld+json',
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data
    }
  }

  async function apiUpdateEntity(endpoint, id, data) {
    try {
      let response = await axios({
        method: 'patch',
        data: data,
        url: api_endpoint + endpoint + '/' + id + '.jsonld',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function patch(endpoint, data) {
    try {
      let response = await axios({
        method: 'patch',
        data: data,
        url: process.env.API_ENTRYPOINT + endpoint,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function apiPostEntity(endpoint, data) {
    try {
      let response = await axios({
        method: 'post',
        data: data,
        url: api_endpoint + endpoint,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function apiPostEntityPublic(endpoint, data) {
    try {
      let response = await axios({
        method: 'post',
        data: data,
        url: process.env.API_ENTRYPOINT + '/' + endpoint,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function apiDeleteEntity(endpoint, id) {
    try {
      let response = await axios({
        method: 'delete',
        url: api_endpoint + endpoint + '/' + id,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function apiFetchCollection(endpoint, params) {
    let itemsPerPage = params.itemsPerPage || 10;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function apiFetchCollectionTest(endpoint, params) {

    let itemsPerPage = params.itemsPerPage || 10;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length) {
      params.filters.map(filter => {
        filtres += '&' + filter.name + '=' + filter.value
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: "http://localhost:4242/" + endpoint + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
        },
      });
      console.log('response', response)
      return response.data
    } catch (e) {
      console.log('e', e)
      responseAuthControl(e.response.data);
      return e.response.data;
    }
  }

  async function apiFetchSubResource(endpoint, params, resource) {
    let id = params.id;
    let itemsPerPage = params.itemsPerPage || 30;
    let page = params.page || 1;
    let filtres = '';
    if (params.filters && params.filters.length > 0) {
      params.filters.map(filter => {
        filtres += '&' + filter['name'] + '=' + filter['value']
      })
    }
    try {
      let response = await axios({
        method: 'get',
        url: api_endpoint + endpoint + '/' + id + '/' + resource + '?itemsPerPage=' + itemsPerPage + '&page=' + page + filtres,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },

      });
      return response.data
    } catch (e) {
      console.log(e.response)
      responseAuthControl(e.response.data);
      return e.response.data
    }
  }

  async function deeplTranslation(lng, sourceLng, texts) {
    try {
      lng = lng.toUpperCase();
      sourceLng = sourceLng ? sourceLng.toLocaleUpperCase() : null
      let data = new FormData();
      data.append('auth_key', process.env.DEEPL_API_KEY);
      data.append('target_lang', lng);
      for (let i = 0; i < texts.length; i++) {
        data.append('text', texts[i])
      }
      if (sourceLng) data.append('source_lang', sourceLng);
      console.log(sourceLng)
      let response = await axios({
        method: 'post',
        data: data,
          url: 'https://api.deepl.com/v2/translate',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
        },
      });
      console.log(response)
      return response.data
    } catch (e) {
      return e.response.data;
    }
  }


  return {
    apiFetchEntity,
    apiUpdateEntity,
    apiFetchCollection,
    apiFetchSubResource,
    apiPostEntity,
    apiDeleteEntity,
    patch,
    apiFetchEntityPublic,
    apiFetchCollectionTest,
    apiPostEntityPublic,
    deeplTranslation
  };
}

const useApiContext = () => {
  return [useDispatchContext()]
}

export {useApiContext, ApiProvider, DispatchContext};
