import React, { useEffect, useRef, useState } from 'react'
import { List, Space } from 'antd';
import { useApiContext } from '../providers/ApiProvider';
import { CloudSyncOutlined, DisconnectOutlined, WalletOutlined } from '@ant-design/icons';
import SellsyConnect from './Modal/SellsyConnect';

export default function ItemList({ onClick, fetch_endpoint, pageSize, contentVersion, filters, selected, ...rest }) {


    onClick = onClick || function () { };

    const [apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: parseInt(pageSize) || 10,
        position: 'bottom',
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const isMounted = useRef(null);
    const [needUpdate, setNeedUpdate] = useState(0)
    const [connectSellsy, setConnectSellsy] = useState(null)

    useEffect(() => {
        isMounted.current = true;

        // fetch at startup and whenever needUpdate is changed
        let params = {
            itemsPerPage: pagination.pageSize,
            page: pagination.current,
            sortField: "createdAt",
            sortOrder: "DESC",
            search: rest.customSearch ? rest.customSearch : {},
            filters: filters
        };
        fetch(params);

        return () => {
            isMounted.current = false;
        }
    }, [needUpdate, contentVersion, rest.updateElement])

    const fetch = async (params) => {
        setLoading(true)
        let hydra_response = await apiFetchCollection(fetch_endpoint, params);
        let pager = { ...pagination };
        if (!isMounted.current) { return }

        if (hydra_response) {
            setError(false);
            pager.total = hydra_response['hydra:totalItems'];
            setPagination(pager)
            setData(hydra_response['hydra:member'])
            // if no result and not in first result page => go to the last available one
            if (hydra_response['hydra:member'].length === 0 && pager.current > 0) {
                pager.current = Math.floor(pager.total / pager.pageSize);
                setPagination(pager);
                setNeedUpdate((new Date()).getTime());
            }
        }
        else {
            setError(true);
        }
        setLoading(false);
    }

    const IconText = ({ icon, text, onClick = () => { } }) => (
        <Space onClick={onClick}>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    return (
        <>
            <List
                itemLayout="vertical"
                bordered
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 10,
                }}
                dataSource={data}
                renderItem={(item) => (
                    <List.Item
                        style={{ backgroundColor: selected?.id === item.id ? '#f3f3f3' : 'initial', cursor: 'pointer' }}
                        onClick={() => onClick(item)}
                        key={item.title}
                        actions={[
                            <IconText icon={WalletOutlined} text="156" key="list-vertical-star-o" />,
                            <IconText icon={item.sellsyId ? CloudSyncOutlined : DisconnectOutlined}
                                text={item.sellsyId ? 'Connecté' : 'En attente'} key="list-vertical-message"
                                onClick={item.sellsyId ? () => {} : () => setConnectSellsy(item)} />,
                        ]}
                    >
                        <List.Item.Meta
                            title={<>
                                <span>{item.name}</span> | <span><small>{item.address || 'adresse nc'} - {item.city || 'ville nc'} - {item.country || 'pays nc'}</small></span>
                            </>
                            }
                        />
                        {item.content}
                    </List.Item>
                )}
            />
            <SellsyConnect onCancel={() => setConnectSellsy(null)} company={connectSellsy} />
        </>
    )
}
