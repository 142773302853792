import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../providers/AuthProvider.js';

export default function Logout() {
    const [authState, authDispatch] = useAuthContext();
    const { authLogout } = authDispatch;

    useEffect(() => {
        if (authState.auth)
            authLogout();
    }, [])

    return <Navigate to="/login" />
};
