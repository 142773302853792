import React, { useState } from "react";
import { message, Modal, Steps } from "antd";

import { useApiContext } from "../../providers/ApiProvider";
import StripeContainer from '../Stripe/StripeContainer'
import CompanyForm from "../CompanyForm";
import CardForm from "../CardForm";
import { useAuthContext } from "../../providers/AuthProvider";
import { useElements, useStripe } from "@stripe/react-stripe-js";

export default function SubscriptionPaymentUpdate({ companyId, subscription, visible, setVisible }) {

    const [authState] = useAuthContext();
    const { auth } = authState;
    const [apiDispatch] = useApiContext();
    const { apiPostEntity, apiUpdateEntity } = apiDispatch;
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [currentStep, setCurrentStep] = useState(0)

    const stripe = useStripe();
    const elements = useElements();

    const handleCompanyChange = (value) => {
        setSelectedCompany(value);
        setCurrentStep(1)
    };

    const handleStepChange = value => {
        setCurrentStep(value)
    }

    const handleCard = async (card) => {
        if (!card) return;

        let defaultPaymentMethod = card.stripeId;
        let setupIntent = await createSetupIntent();
        if (!setupIntent) return setLoading(false);

        let cardSetup = await handleCardSetup(setupIntent.client_secret, defaultPaymentMethod);
        console.log('cardSetup', cardSetup)
        if (cardSetup.status === "succeeded") {

            const responseApi = await apiUpdateEntity("subscriptions", subscription.id, {
                paymentMethodId: cardSetup.payment_method,
            });
            console.log('responseApi', responseApi)
            message.success("Le moyen de paiement a été mis a jour avec succès");
            setVisible(false)
        }
        
    }

    const createSetupIntent = async () => {
        let response = await apiPostEntity('stripe/setupIntent', {
            accountId: auth.accountId
        })
        if (response.error) {
            message.error(response.error.message);
        }
        console.log('response', response)
        return response.setupIntent;
    }

    
    const handleCardSetup = async (clientSecret, paymentMethod) => {
        // let clientSecret = subscriptionData.pending_setup_intent.client_secret
        const data = { payment_method: paymentMethod }

        let { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, data)

        if (error) {
            message.error(error.message);
        }
        return setupIntent;
    }

    return (
        <Modal visible={visible} title="Mise à jour du moyen de paiement" onCancel={() => setVisible(false)}>
                <Steps size="small" type="navigation" current={currentStep} onChange={handleStepChange}>
                    <Steps.Step title="Société" status={currentStep === 0 ? "process" : "finish"} />
                    <Steps.Step title="Moyen de paiement" status={currentStep === 1 ? "process" : "wait"} disabled={!selectedCompany} />
                </Steps>
                {
                    currentStep === 0 &&
                    <CompanyForm handleCompanyChange={handleCompanyChange} />
                }
                {selectedCompany && currentStep === 1 && (
                    <CardForm selectedCompany={selectedCompany} subscription={subscription} handleCard={handleCard} />
                )}
        </Modal>
    );
}
