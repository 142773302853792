import { Button, DatePicker, message, Popover } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useApiContext } from '../../providers/ApiProvider';

export default function PauseAction({ subscription, actionLoading, setActionLoading, setNeedUpdate }) {

    const [visible, setVisible] = useState(false);
    const [pauseDate, setPauseDate] = useState(null);
    const [apiDispatch] = useApiContext();
    const { apiUpdateEntity } = apiDispatch;

    const handlePause = async () => {
        setActionLoading(true)
        let params = {
            pauseCollection: {
                behavior: 'void',
                resumes_at: pauseDate.unix()
            }
        }
        let response = await apiUpdateEntity('subscriptions', subscription.id, params)
        console.log('response', response)
        setActionLoading(false)
        if (response['@type'] === "hydra:Error") {
            return message.error(response['hydra:description'])
        }

        setNeedUpdate((new Date()).getTime())
        return message.success('Abonnement mis en pause avec succes')
    }

    const hide = () => {
        setVisible(false);
    };

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };

    const onChange = (date, dateString) => {
        console.log(date, dateString);
        setPauseDate(date)
    };

    const isDisabled = (currentDate) => {
        let now = dayjs()

        return now.isAfter(currentDate)
    }

    return (
        <Popover
            content={<>
                <p>Choisissez la date à laquel votre abonnement reprendra</p>
                <p>
                    <DatePicker onChange={onChange} value={pauseDate} disabledDate={isDisabled} />
                </p>
                <Button loading={actionLoading} onClick={hide} >Annuler</Button>
                <Button type="primary" disabled={!pauseDate} loading={actionLoading} onClick={handlePause} >Mettre en pause</Button>
            </>}
            title="Mise en pause de votre abonnement"
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button >Mettre en Pause</Button>
        </Popover>
    )
}
