import React, { useState } from 'react'
import { Form, Input, Button, Table, InputNumber } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Column } = Table;


export default function TiersFormTable({ tiersData, tiers, add, remove }) {

    function handleAdd() {
        console.log('tiers', tiers)
        add({ flat_amount: 0, unit_amount: 0 })
    }

    return (
        <Table
            size="small"
            dataSource={tiers}
            pagination={false}
            footer={() => {
                return (
                    <Form.Item>
                        <Button onClick={handleAdd}>
                            <PlusOutlined /> Add field
                        </Button>
                    </Form.Item>
                );
            }}
        >
            <Column
                dataIndex={"up_to"}
                title={"Max"}
                render={(value, row, index) => {
                    console.log(tiersData);
                    let min = index === 0 ? 0 : tiersData?.[index - 1].up_to + 1
                    console.log('min', min)
                    return (
                        <Form.Item
                            name={[row.name, "up_to"]}
                        >
                            <InputNumber min={min} size="small" placeholder='&infin;' disabled={tiersData?.length === index + 1}/>
                        </Form.Item>
                    );
                }}
            />
            <Column
                dataIndex={"unit_amount"}
                title={"Prix unité"}
                render={(value, row, index) => {
                    console.log('value', value, row)
                    return (
                        <Form.Item
                            name={[row.name, "unit_amount"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Missing price",
                                },
                            ]}
                        >
                            <InputNumber min={0} size="small" />
                        </Form.Item>
                    );
                }}
            />
            <Column
                dataIndex={"flat_amount"}
                title={"Frais fixes"}
                render={(value, row, index) => {
                    return (
                        <Form.Item
                            name={[row.name, "flat_amount"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Missing price",
                                },
                            ]}
                        >
                            <InputNumber min={0} size="small" />
                        </Form.Item>
                    );
                }}
            />
            <Column
                title={"Action"}
                render={(value, row, index) => {

                    return (
                        <React.Fragment>
                            {
                                tiersData?.length !== index + 1 &&
                                <Button
                                    size="small"
                                    icon={<MinusOutlined />}
                                    shape={"circle"}
                                    onClick={() => remove(row.name)}
                                />
                            }
                        </React.Fragment>
                    );
                }}
            />
        </Table>
    )
}
