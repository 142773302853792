import React, { useState } from 'react'
import { Select } from 'antd'
import { useApiContext } from '../../providers/ApiProvider';

export default function AutoCompleteAccount({handleSelect = ()=>{}}) {

    const [apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;
    const [data, setData] = useState([]);
    const [value, setValue] = useState('');

    const fetchAccounts = async (val) => {

        const response = await apiFetchCollection('accounts', { filters: [{ name: 'name', value: val.trim() }] })
        console.log('response', response)
        setData(response['hydra:member'])

    }

    const handleSearch = (newValue) => {
        if (newValue) {
            fetchAccounts(newValue);
        } else {
            setData([]);
        }
    };

    const handleChange = (newValue) => {
        console.log('newValue', newValue)
        setValue(newValue);
        handleSelect(newValue)
    };

    const options = data.map((d) => <Select.Option key={d['@id']}>{d.name}</Select.Option>);


    return (
        <Select
            showSearch
            value={value}
            placeholder="Recherche un compte"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
        >
            {options}
        </Select>
    )
}
