import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import SubscriptionForm from "./SubscriptionForm";
import Login from "./Login";
import {useAuthContext} from "../../providers/AuthProvider";

const Identification = ({setCurrentStep}) => {

    const {t} = useTranslation();
    const [authState, authDispatch] = useAuthContext();
    const {authIsLogged} = authDispatch;

    const [view, setView] = useState('subscription')

    if (authIsLogged())
        return 'all ready logged'

    return (
        <div>

            {view === 'subscription' &&
                <SubscriptionForm setCurrentStep={setCurrentStep} setView={setView}/>}

            {view === 'login' &&
                <Login setCurrentStep={setCurrentStep} setView={setView}/>}

        </div>
    );
};

export default Identification;