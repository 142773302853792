import React, { useState } from 'react'
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Select, Spin, Switch } from "antd";
import { useApiContext } from '../../providers/ApiProvider';
import AutoCompleteAccount from '../account/AutoCompleteAccount';

export default function PromotionCodesForm({ handleClose, promoCode, modal = true, coupon }) {

  const [apiDispatch] = useApiContext();
  const { apiPostEntity, apiUpdateEntity } = apiDispatch;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const limitUse = Form.useWatch("limitUse", form);

  const handleSubmit = (values) => {

    if (promoCode?.id === "new") {
      console.log('values', values)
      createCode(values)
    }

  }

  const handleAccount = (value) => {
    form.setFieldsValue({ account: value })
  }

  const createCode = async (values) => {
    // return console.log('tiers', values.tiers)
    if(!coupon) return message.error('Il y a eu un problème')
    values.coupon = coupon['@id']
    const response = await apiPostEntity("promotion_codes", values);
    if (response["@type"] === "hydra:Error") {
      return message.error(response["hydra:description"]);
    }
    return message.success('Code promo crée avec succès.')
  };

  const content = (
    <Spin spinning={loading}>
      <Form
        onFinish={handleSubmit}
        // layout="vertical"
        form={form}
        initialValues={{
          code: promoCode?.code || '',
          firstTimeTransaction: promoCode?.firstTimeTransaction || false,
          expireAt: promoCode?.expireAt,
          account: promoCode?.account || null
        }}
      >
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true, message: "Le code est requis" }]}
        >
          <Input placeholder="Code" />
        </Form.Item>
        {promoCode?.id === "new" && (
          <>
            <Form.Item name="firstTimeTransaction" valuePropName="checked">
              <Checkbox >Utilisable uniquement lors du premier achat</Checkbox>
            </Form.Item>

            <Form.Item
              label="Date d'expiration"
              name="expireAt"
            >
              <DatePicker allowClear />
            </Form.Item>
            <Form.Item label="Limiter à un client" name="account" >
              <AutoCompleteAccount handleSelect={handleAccount} />
            </Form.Item>
            <Form.Item name="limitUse" valuePropName="checked">
              <Checkbox >Limiter le nombre d'utilisation du code</Checkbox>
            </Form.Item>
            {
              limitUse &&
              <Form.Item label="Nombre d'utilisation" name="maxRedemptions" >
                <InputNumber min={1} />
              </Form.Item>
            }
          </>
        )}
        {!modal && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </Spin>
  );

  return (
    <Modal
      title="Ajouter code promo"
      visible
      onOk={() => form.submit()}
      onCancel={handleClose}
      okText="Créer"
      cancelText="Annuler"
    >
      {content}
    </Modal>
  )
}
