import React, { useState } from 'react'
import { useApiContext } from '../../providers/ApiProvider';
import { Button, Form, Input, InputNumber, message, Modal, Spin } from 'antd'

export default function ProductForm({ modal, handleClose }) {

    const [apiDispatch] = useApiContext();
    const { apiPostEntity } = apiDispatch;
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    const handleProductCreate = async values => {
        console.log('values', values)
        setLoading(true)
        const response = await apiPostEntity('products', values)
        console.log('response', response)
        if (response['@type'] === "hydra:Error") {
            return message.error(response['hydra:description'])
        }
        handleClose(true)
        setLoading(false)
    }
    console.log('form', form)

    const content = (
        <Spin spinning={loading}>
            <Form onFinish={handleProductCreate} layout="vertical" form={form}>
                <Form.Item label="Nom du produit" name="name"
                    rules={[{ required: true, message: 'Le nom est requis!' }]}
                >
                    <Input placeholder="input product name" />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input placeholder="input description" />
                </Form.Item>
                <Form.Item label="Url" name="url"
                    rules={[{ required: true, message: 'L\'url est requise!' }]}>
                    <Input placeholder="url du produit" />
                </Form.Item>
                <Form.Item label="Période d'essai" name="trialPeriod">
                    <InputNumber placeholder="0" />
                </Form.Item>
                {
                    !modal &&
                    <Form.Item >
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                }
            </Form>
        </Spin>
    )

    if (!modal) return content;


    return (
        <Modal title="Créer un produit Stripe" visible onOk={() => form.submit()} onCancel={handleClose} okText="Créer" cancelText="Annuler">
            {content}
        </Modal>
    )
}
