import React from 'react'
import { Layout } from 'antd';
import { CheckoutProvider } from '../../providers/CheckoutProvider';

const { Content } = Layout;

export default function PublicLayout({ children }) {

    return (
        <CheckoutProvider>
            <Layout className="mainLayout">
                <Content>
                    <Layout >
                        <Content>
                            {children}
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        </CheckoutProvider>
    )
}