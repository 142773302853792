import React, { useState } from 'react'
import { Button, Space } from 'antd'
import CollectionTable from '../../components/CollectionTable';
import ProductForm from '../../components/Stripe/ProductForm';
import { useNavigate } from 'react-router-dom';

export default function Products() {

    const navigate = useNavigate()

    const [isCreating, setIsCreating] = useState(false);
    const [reload, setReload] = useState(0);

    const handleCreate = () => {
        setIsCreating(true)
    }

    const handleClose = (shouldReload) => {
        setIsCreating(false)
        if (shouldReload) {
            setReload((new Date()).getTime())
        }
    }


    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Url',
            dataIndex:'url',
            key: 'url'
        },
        {
            title: 'Stripe id',
            dataIndex: 'stripeProductId',
            key: 'stripeProductId',
        },
        {
            title: 'Période d\'essai (jours)',
            dataIndex: 'trialPeriod',
            key: 'trialPeriod'
        },
        {
            title: 'Prix lié',
            dataIndex: 'prices',
            key: 'prices',
            render: prices => prices.length
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => 
                <Button onClick={() => navigate(`/admin/products/${id}`)}>+</Button>
        },
    ]


    return (
        <>
            <h1>Produits</h1>
            <div>
                <Button onClick={handleCreate}>Créer un produit</Button>
            </div>
           <CollectionTable endpoint="products" columns={columns} reload={reload}/>

            {
                isCreating &&
                <ProductForm modal={true} handleClose={handleClose}/>
            }


        </>
    )
}
