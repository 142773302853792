import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useAuthContext } from "../../providers/AuthProvider";
import { Button, Form, Modal, Spin } from "antd";
import { useApiContext } from "../../providers/ApiProvider";

const options = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CardForm({ companyId, visible, handleClose = ()=> {} }) {
  const [authDispatch] = useAuthContext();
  const { auth } = authDispatch;
  const [apiDispatch] = useApiContext();
  const { apiPostEntity } = apiDispatch;

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (values) => {
    const cardElement = elements.getElement(CardElement);
    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    console.log("cardElement", paymentMethod);
    if (error) {
      return error.message;
    }

    let data = {
      company: 'api/companies/' + companyId,
      stripeId: paymentMethod.id,
      lastFour: paymentMethod.card.last4,
      expMonth: paymentMethod.card.exp_month,
      expYear: paymentMethod.card.exp_year,
      brand: paymentMethod.card.brand,
      customer: paymentMethod.customer,
    };
    
    let response = await apiPostEntity("cards", data);

    console.log(response);
  };

  if (!stripe && !elements) {
    return <Spin spinning={true} />;
  }

  return (
    <Modal title="Ajouter une carte" visible={visible} onCancel={handleClose}>
      <p>Card : 4242424242424242</p>
      <p>Card verif : 4000002500003155</p>
      <p>Card error: 4000 0000 0000 9995</p>

      <Form id="subscribe-form" onFinish={handleSubmit}>
        <CardElement options={options} />

        <Button htmlType="submit">Ajouter</Button>

        <div id="messages"></div>
      </Form>
    </Modal>
  );
}
