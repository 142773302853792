import React, { useState } from 'react'
import { Button } from 'antd';
import ApiCollectionLatest from '../ApiCollectionLatest';
import Subscription from './Subscription';
import dayjs from 'dayjs'

export default function SubscriptionDash({ fetch_endpoint }) {

    const [selectedSub, setSelectedSub] = useState(null)

    const columns = [
        {
            dataIndex: "name",
            key: "name",
            title: "Nom",
        },
        {
            dataIndex: "status",
            key: "status",
            title: "Status",
        },
        {
            dataIndex: "currentPeriodEnd",
            key: "period",
            title: "Période",
            render: (currentPeriodEnd, record) => dayjs(record.currentPeriodEnd).format('DD-MM-YY')
        }
    ];

    return (
        <>
            <ApiCollectionLatest
                fetch_endpoint={fetch_endpoint}
                contentVersion={fetch_endpoint}
                columns={columns}
                onClick={(item) => setSelectedSub(item.id)}
                title={() => (
                    <>
                        Abonnements
                    </>
                )}
            />

            <Subscription subscriptionId={selectedSub} onClose={() => setSelectedSub(null)} />

        </>
    )
}
