import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Button, Card, InputNumber, Space, Spin} from 'antd';
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import StripeElements from '../../components/Stripe/StripeElements';
import {useApiContext} from '../../providers/ApiProvider';
import {useCheckoutContext} from "../../providers/CheckoutProvider";

const stripePromise = loadStripe(process.env.STRIPE_PUBLICKEY)

export default function Checkout() {


    // const brand = {
    //     "@id": "/api/brands/1",
    //     "@type": "Brand",
    //     "id": 1,
    //     "name": "nimp"
    // }
    //
    // const company = {
    //     "@id": "/api/companies/1",
    //     "@type": "Company",
    //     "id": 1,
    //     "name": "MMcreation",
    //     "address": "221 Rue Saint-Honoré",
    //     "zipCode": "75001",
    //     "city": "Paris",
    //     "country": "France"
    // }
    //
    // const account = {
    //     customerId: 'cus_Ln0P0hiCPYW0Ay'
    // }

    // const productId = 1;
    // const priceId = 1;

    const [checkoutState, checkoutDispatch] = useCheckoutContext();
    const {account} = checkoutState;

    const [apiDispatch] = useApiContext();
    const {apiFetchEntity, apiPostEntity} = apiDispatch;

    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedPrice, setSelectedPrice] = useState(null)
    const [subscriptionData, setSubscriptionData] = useState(null)
    const [interval, setInterval] = useState('month')
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [quantity, setQuantity] = useState(1);

    const params = useParams()

    useEffect(() => {
        fetchProduct()
        fetchPrice()
    }, [])

    const fetchProduct = async () => {
        console.log('params', params)
        setLoading(true)
        let response = await apiFetchEntity('products', params.productId)
        console.log('response', response)
        setProduct(response)
        setLoading(false)
    }

    const fetchPrice = async () => {
        let response = await apiFetchEntity('prices', params.priceId)
        console.log('response', response)
        setSelectedPrice(response)
    }

    const createSubscription = async (priceId) => {
        console.log('product', product.prices.find(item => item.active && item.type === 'setup'))
        let data = {
            priceId,
            trial: product.trialPeriod,
            setup: product.prices.find(item => item.active && item.type === 'setup')?.stripePriceId || null,
            quantity: quantity,
            customerId: account.stripeUserId
        }
        const response = await apiPostEntity('stripe/subscription', data)
        console.log('response', response)
        setSubscriptionData(response.subscription)
    }

    const calculateTotal = () => {
        let tiers = selectedPrice.tiers;
        let correspondingTier = tiers.find(tier => tier.up_to !== 'inf' && tier.up_to >= quantity)
        if (correspondingTier) {
            return correspondingTier.flat_amount + correspondingTier.unit_amount * quantity
        } else {
            let infTier = tiers.find(tier => tier.up_to === 'inf' || !tier.up_to)
            return infTier.flat_amount + infTier.unit_amount * quantity
        }
    }

    const handleSuccess = (paymentIntent) => {
        setPaymentIntent(paymentIntent)
    }

    if (paymentIntent) {
        return (
            <div>
                <h2>Abonnement souscris avec succes</h2>

            </div>
        )
    }

    return (
        <div>
            <h2>Checkout {product?.name}</h2>
            {
                subscriptionData ?
                    <Elements stripe={stripePromise}>
                        <StripeElements subscriptionData={subscriptionData} handleSuccess={handleSuccess}/>
                    </Elements>
                    :
                    <Spin spinning={loading}>
                        <Space>
                            {/* <Switch size='small' checked={interval === 'month'} onChange={(checked) => setInterval(prev => prev === 'month' ? 'year' : 'month')} /> */}
                            {/* {interval === 'month' ? 'Au mois ' : 'A l\'année (-20%) '} */}
                        </Space>
                        <p>Compris dans ce produit :</p>
                        <ul>
                            {
                                product?.prices.filter(item => item.type === 'setup').map(price =>
                                    <li>{price?.name} : {price?.amount}{price?.currency}</li>
                                )
                            }
                            {
                                product?.trialPeriod > 0 && <li>Période d'essai de {product.trialPeriod} jours</li>
                            }
                        </ul>
                        {
                            !selectedPrice ?
                                <div className="flex">
                                    {
                                        product?.prices.filter(item => item.type === 'price' && item.recurringInterval === interval).map(price =>
                                            <Card className="login-box" key={price.id}>
                                                <h3>{price.name}</h3>
                                                <p>{price.amount} {price.currency}</p>
                                                <p>Par {price.recurringInterval === 'month' ? 'mois' : 'an'}.</p>
                                                <Button
                                                    onClick={() => createSubscription(price.stripePriceId)}>GO</Button>
                                            </Card>
                                        )
                                    }
                                </div>
                                :
                                <div>
                                    <Card className="login-box" key={selectedPrice.id}>
                                        <h3>{selectedPrice.name}</h3>
                                        {
                                            selectedPrice.billingScheme === 'per_unit' ?
                                                <p>{selectedPrice.amount} {selectedPrice.currency}</p>
                                                :
                                                <Space>
                                                    <InputNumber min={1} value={quantity}
                                                                 onChange={value => setQuantity(value)}/>
                                                    Prix a payé : {calculateTotal()} €
                                                    <span>{selectedPrice.reccuring && `Par ${selectedPrice.recurringInterval === 'month' ? 'mois' : 'an'}. `}</span>

                                                </Space>
                                        }
                                        <Button
                                            onClick={() => createSubscription(selectedPrice.stripePriceId)}>GO</Button>
                                    </Card>
                                </div>
                        }
                    </Spin>
            }
        </div>
    )
}
