import React, {useEffect, useState} from 'react'
import {Button, Modal, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import CollectionTable from "../../components/CollectionTable";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import ModalAccount from "../../components/account/ModalAccount";
import ModalUserPermission from "./ModalUserPermission";
import { useNavigate } from 'react-router-dom';

const {confirm} = Modal;

export default function AccountList() {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch;

    const [needUpdate, setNeedUpdate] = useState(0);

    const [visible, setVisible] = useState(false);
    const [accountSelected, setAccountSelected] = useState(null);

    const [visiblePermission, setVisiblePermission] = useState(false);
    const [permissionSelected, setPermissionSelected] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const navigate = useNavigate()

    const columns = [
        {
            title: 'Stripe Id',
            dataIndex: 'stripeUserId'
        },
        {
            title: 'Admin',
            dataIndex: 'id',
            render: (id, record) => (
                !!(record.permissions && record.permissions.length) ?
                    <Space>
                        <span className={console.log(record)}>{record.permissions[0].user.firstname}</span>
                        <span>{record.permissions[0].user.lastname}</span>
                        <span> | </span>
                        <span>{record.permissions[0].user.email}</span>
                        <span><EditTwoTone onClick={() => setPermissionSelected(record.permissions[0].user)}/></span>
                        {/* <span><DeleteTwoTone twoToneColor="red"
                                             onClick={() => showDeleteConfirm('permissions', id)}/></span> */}
                    </Space> : <Button icon={<PlusOutlined/>} size="small" onClick={() => {
                        setVisiblePermission(true)
                        setAccountId(id)
                    }}/>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (id, record) => (
                <Space size="large">
                    <EyeOutlined onClick={() => navigate(`/admin/account/${id}`)}/>
                    <EditTwoTone onClick={() => setAccountSelected(record)}/>
                    <DeleteTwoTone twoToneColor="red" onClick={() => showDeleteConfirm('accounts', id)}/>
                </Space>
            )
        }
    ]

    useEffect(() => {
        if (accountSelected)
            setVisible(true)
    }, [accountSelected])

    useEffect(() => {
        if (!visible && accountSelected)
            setAccountSelected(null)
    }, [visible])

    useEffect(() => {
        if (permissionSelected)
            setVisiblePermission(true)
    }, [permissionSelected])

    useEffect(() => {
        if (!visiblePermission) {
            setPermissionSelected(null)
            setAccountId(null)
        }
    }, [visiblePermission])

    function showDeleteConfirm(entity, id) {
        confirm({
            title: t('Etes-vous sûr de vouloir supprmer cet element ?'),
            icon: <ExclamationCircleOutlined/>,
            content: t('Cet element sera perdu définitivement'),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    const response = await apiDeleteEntity(entity, id);
                    setNeedUpdate(needUpdate + 1)
                    resolve()
                }).catch(() => console.log('Oops errors!'));

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <Space direction="vertical" size="large" style={{width: '100%'}}>

            <div>
                <Button
                    type="primary" onClick={() => setVisible(true)}
                    icon={<PlusOutlined/>}
                >
                    Ajouter un compte
                </Button>
            </div>


            <CollectionTable
                endpoint="accounts"
                columns={columns}
                reload={needUpdate}
            />

            {visible &&
                <ModalAccount
                    visible={visible}
                    setVisible={setVisible}
                    account={accountSelected}
                    reload={() => setNeedUpdate(new Date().getTime())}
                />}


            {visiblePermission &&
                <ModalUserPermission
                    visible={visiblePermission}
                    setVisible={setVisiblePermission}
                    user={permissionSelected}
                    reload={() => setNeedUpdate(new Date().getTime())}
                    accountId={accountId}
                />}

        </Space>
    )
}