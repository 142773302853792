import React from "react";
import ApiCollectionLatest from "../../components/ApiCollectionLatest";

import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import SubscriptionDash from "../../components/Subscription/SubscriptionDash";

export default function Dashboard() {
  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { t } = useTranslation();
  const navigate = useNavigate();

  console.log("auth", auth);

  const companiesColumns = [
    {
      dataIndex: "name",
      key: "name",
      title: "Nom",
    },
    {
      dataIndex: "address",
      key: "address",
      title: "Adresse",
    },
  ];

  return (
    <>
      <h1>Bienvenue sur votre compte MMC payment</h1>

      <ApiCollectionLatest
        onClick={(item) => navigate(`/company/${item.id}`)}
        fetch_endpoint={`accounts/${auth.accountId}/companies`}
        columns={companiesColumns}
        title={() => <h2>Société</h2>}
      />

      <SubscriptionDash fetch_endpoint={`accounts/${auth.accountId}/subscriptions`}/>
    </>
  );
}
