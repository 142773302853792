import React, {useState} from 'react';
import {Form, Input, Modal, Select} from "antd";
import {useAuthContext} from "../../providers/AuthProvider";
import {useApiContext} from "../../providers/ApiProvider";

const ModalContact = ({visible, setVisible, contact, reload}) => {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch;

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)

    function handleSubmit() {
        form
            .validateFields()
            .then(values => {
                if (contact)
                    update(values)
                else
                    create(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    async function create(values) {
        setLoading(true)
        values.account = `/api/accounts/${auth.accountId}`
        const response = await apiPostEntity('contacts', values)
        reload()
        form.resetFields();
        setLoading(false)
        setVisible(false)
    }

    async function update(values) {
        setLoading(true)
        const response = await apiUpdateEntity('contacts', contact.id, values)
        reload()
        form.resetFields();
        setLoading(false)
        setVisible(false)
    }

    return (
        <Modal
            confirmLoading={loading}
            destroyOnClose
            title={'Ajouter un contact'}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={handleSubmit}
        >

            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    firstname: contact ? contact.firstname : '',
                    lastname: contact ? contact.lastname : '',
                    email: contact ? contact.email : '',
                    phone: contact ? contact.phone : '',
                    role: contact ? contact.role : 'facturation'
                }}
            >

                <Form.Item
                    label="Prénom"
                    name="firstname"
                    rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nom"
                    name="lastname"
                    rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ type: 'email', required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Téléphone"
                    name="phone"
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Ce champs est obligatoire' }]}
                >
                    <Select>
                        <Select.Option value="facturation">Facturation</Select.Option>
                    </Select>
                </Form.Item>

            </Form>

        </Modal>
    );
};

export default ModalContact;