import React, {useEffect, useState} from 'react';
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";
import {useCheckoutContext} from "../../providers/CheckoutProvider";
import {Button, Card, Space, Spin, message} from "antd";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useParams} from "react-router-dom";

const options = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d",
            },
        },
        invalid: {
            fontFamily: "Arial, sans-serif",
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const CardSelection = ({selectedCard, setSelectedCard, product, quantity, handleSuccess}) => {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiPostEntity} = apiDispatch;

    const stripe = useStripe();
    const elements = useElements();

    const [checkoutState] = useCheckoutContext();
    const {company, account, brand} = checkoutState;

    const params = useParams()
    // const company = {id: 1}

    useEffect(() => {
        setLoading(true)
        fetch()
    }, [])

    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingPayment, setLoadingPayment] = useState(false)

    async function fetch() {
        const response = await apiFetchSubResource('companies', {id: company.id}, 'cards');
        setCards(response['hydra:member'])
        setLoading(false)
    }

    function toggleSelect(id) {
        setSelectedCard(id === selectedCard ? '' : id)
    }

    async function handleClick() {
        // on check si une card est selected
        // si non on la cré puis on injecte le stripe id dans
        setLoadingPayment(true)
        let defaultPaymentMethode = selectedCard

        if (!selectedCard) {
            console.log('create card');

            const response = await createCard();
            console.log(response);

            if (response.error) {
                setLoadingPayment(false)
                message.error(response.error.message)
            }

            defaultPaymentMethode = response.card.stripeId
        }

        console.log('create sub');
        // ensuite on créé la sub puis on envoie la methode de peiement stripe

        const subscriptionStripe = await createSubscription(defaultPaymentMethode)
        console.log('response', subscriptionStripe)

        let response;
        if (subscriptionStripe.pending_setup_intent) {
            response = await handleCardSetup(subscriptionStripe.pending_setup_intent.client_secret, defaultPaymentMethode)
        } else {
            response = await handlePayment(subscriptionStripe.latest_invoice.payment_intent.client_secret, defaultPaymentMethode)
        }

        if (response.error) {
            return message.error(response.error.message)
        }
        console.log('response', response.data)

        if (response.data.status === 'succeeded') {
            message.success('Inscription validé avec succes');
            handleSuccess(response.data)
            const responseApi = await createSubscriptionMMCAPI(subscriptionStripe, response)
        }

        setLoadingPayment(false)
    }

    const createSubscriptionMMCAPI = async (subscriptionStripe, paymentResponse) => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret

        let now = new Date()
        const response = await apiPostEntity('subscriptions', {
            stripeSubId: subscriptionStripe.id,
            status: 'active',
            prices: [params.priceId],
            companyId: company['id'],
            brandId: brand['id'],
            paymentMethodId: paymentResponse.data.payment_method,
            stripeItems: subscriptionStripe.items.data,
            createdAt: new Date(),
            currentPeriodStart: new Date(),
            currentPeriodEnd: new Date(now.setMonth(now.getMonth() + 1))
        })

        console.log(response)
    }

    const createSubscription = async (defaultPaymentMethode) => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret
        const data = {
            priceId: product.prices.find(item => item.id === Number(params.priceId))?.stripePriceId || null,
            trial: product.trialPeriod,
            setup: product.prices.find(item => item.active && item.type === 'setup')?.stripePriceId || null,
            quantity: quantity,
            customerId: account.stripeUserId,
            defaultPaymentMethode: defaultPaymentMethode
        }

        const response = await apiPostEntity('stripe/subscription', data)

        return response.subscription
    }

    const createCard = async () => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret
        const cardElement = elements.getElement(CardElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });

        console.log("cardElement", paymentMethod);
        console.log("error", error);

        if (error)
            return {error};

        let data = {
            company: 'api/companies/' + company.id,
            stripeId: paymentMethod.id,
            lastFour: paymentMethod.card.last4,
            expMonth: paymentMethod.card.exp_month,
            expYear: paymentMethod.card.exp_year,
            brand: paymentMethod.card.brand,
            customer: paymentMethod.customer,
        };

        const card = await apiPostEntity("cards", data);

        return {card}
    }


    const handlePayment = async (clientSecret, paymentMethod) => {
        // let clientSecret = subscriptionData.latest_invoice.payment_intent.client_secret

        const data = {
            payment_method: paymentMethod
        }

        let {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, data)
        return {error: error, data: paymentIntent}
    }

    const handleCardSetup = async (clientSecret, paymentMethod) => {
        // let clientSecret = subscriptionData.pending_setup_intent.client_secret

        const data = {
            payment_method: paymentMethod
        }

        let {error, setupIntent} = await stripe.confirmCardSetup(clientSecret, data)
        return {error, data: setupIntent}
    }

    return (
        <Space direction="vertical" size="large">
            <div className="mb-1em">
                <h3>Séléctionné votre carte</h3>

                <Spin spinning={loading}>
                    <Space wrap>
                        {cards.map(card => (
                            <Card
                                key={card.id} title={card.brand} onClick={() => toggleSelect(card.stripeId)}
                                style={{
                                    cursor: 'pointer',
                                    border: selectedCard === card.stripeId ? '1px solid #2494d1' : null,
                                    boxShadow: selectedCard === card.stripeId ? '0px 0px 6px 0px #2494d1' : null
                                }}
                            >
                                <p>**** **** **** {card.lastFour}</p>
                                <p>{card.expMonth.toString().padStart(2, '0')}/{card.expYear}</p>
                            </Card>
                        ))}
                    </Space>
                </Spin>

            </div>

            <div className="mb-1em">
                <h3>Ou payer avec une autre carte</h3>

                <CardElement options={options}/>
            </div>

            <div>
                <Button type="primary" onClick={handleClick} loading={loadingPayment}>Payer</Button>
            </div>
        </Space>
    );
};

export default CardSelection;