import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.STRIPE_PUBLICKEY);

export default function StripeContainer({children}) {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
}
